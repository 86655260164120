@import "~@/erp/styles/variables/variables.scss";


































































































.query-dialog {
  .el-dialog__body {
    padding: 20px 30px !important;
  }
  .el-dialog__footer {
    .dialog-footer {
      padding: 10px 100px !important;
      display: flex !important;
      justify-content: center !important;
    }
  }
}
