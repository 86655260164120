@import "~@/erp/styles/variables/variables.scss";












































































































































































































































































































































































































































































.custom-container {
  padding: 0 20px 20px 20px;
}

.audit-wrapper {
  padding: 0 20px 20px 20px !important;
}

::v-deep {
  .el-table__footer-wrapper {
    .has-gutter {
      height: 40px;
      font-family: 'PingFang Bold';
      .required-th::before {
        display: none;
      }
      td {
        text-align: center;
      }
    }
  }
}
.mr5 {
  margin-right: 5px;
}
