@import "~@/erp/styles/variables/variables.scss";






































































































.product-info {
  margin-bottom: 50px;
  padding:10px 25px  25px;
}
