@import "~@/erp/styles/variables/variables.scss";















































































































































































































































.shelves-dialog {
  width: 480px !important;
  .dialog-cont {
    padding: 0 20px;
  }
  .cont-title {
    margin-bottom: 20px;
  }
}
