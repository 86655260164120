@import "~@/erp/styles/variables/variables.scss";









































































































































































































































































































































































































































































































































































.content {
  padding: 0 20px 0px;
  .detail-header {
    > .el-col {
      p {
        margin-bottom: 14px;
      }
      > span {
        font-size: 16px;
        color: #000;
      }
    }
  }
  .remark {
    > .el-col {
      p {
        margin-bottom: 14px;
      }
      > span {
        font-size: 16px;
        color: #000;
      }
    }
  }
  /deep/ .el-divider--horizontal {
    margin: 30px 0;
  }
}
::v-deep {
  .el-table__expanded-cell {
    padding: 0 0 0 0;
  }
  .el-table__expand-column .el-icon {
    visibility: hidden;
  }
  // .bottom-table
  //   > .el-table__header-wrapper
  //   > .el-table__header
  //   > .has-gutter
  //   > tr
  //   > th:nth-child(5) {
  //   background-color: #fff;
  //   border: none;
  // }
}
