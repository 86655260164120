@import "~@/erp/styles/variables/variables.scss";

































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.pro-container {
  .left-h6 {
    display: flex;
    font-size: 16px;
    font-family: 'PingFang Bold';
  }

  .left-h6::before {
    content: '';
    display: block;
    height: 16px;
    width: 2px;
    background: #282c34;
    margin-right: 5px;
    margin-top: 4px;
  }

  .right-content {
    height: 100%;
    border: 1px solid #e4e7ed;
    padding: 20px !important;
    border-radius: 3px;
  }
}

.calcu-p {
  font-size: 12px;
  color: #999;
  line-height: 32px;
}

.cell-center {
  transform: translateY(5px);
}

.pa {
  position: absolute;
  right: 0;
}

.dash_line {
  border: 1px dashed #e4e7ed;
  transform: translateY(-12px);
}

::v-deep .append-select {
  width: 74px !important;
  padding: 0 2px !important;

  .el-input__suffix {
    right: 10px;
  }
}
