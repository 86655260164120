@import "~@/erp/styles/variables/variables.scss";







































































































































































































































































































































































































































































































































































































































































































































































::v-deep {
  .el-table__expanded-cell {
    padding: 0 !important;
  }
  .el-table__body tr {
    min-height: 0px !important;
  }
  .shipped-flag {
    .el-checkbox {
      &.is-disabled {
        .el-checkbox__inner {
          background-color: #f2f2f2 !important;
          border-color: #f2f2f2 !important;
        }
      }
    }
  }
}
.jz {
  display: flex;
  align-items: center;
  justify-content: center;
}
.el-tag-other {
  position: absolute;
  right: 4px;
  z-index: 2;
  background-color: #fff7e8;
  border-color: #ffefd1;
  color: #ffae18;
}
::v-deep .query-dialog {
  .el-dialog__header {
    border-bottom: 1px solid #c0c4cc;
  }
  .el-dialog__body {
    min-height: 400px;
    max-height: 600px;
    overflow-y: auto;
  }
  .el-dialog__footer {
    padding: 20px;
    border-top: 1px solid #c0c4cc;
  }
}
.text-d7 {
  color: #999 !important;
}
