@import "~@/erp/styles/variables/variables.scss";































































































































































































































































































































































.dialog-content {
  .link {
    color: #1890ff;
    cursor: pointer;
  }
}
::v-deep {
  .el-select {
    width: 100%;
  }
}
