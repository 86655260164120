@import "~@/erp/styles/variables/variables.scss";















































































































































.dialog-content {
  .el-form {
    width: 95%;
    margin: 0 auto;
  }
}
::v-deep {
  .el-select {
    width: 100%;
  }
}
.text-hidden {
  width: 150px;
}
