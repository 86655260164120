@import "~@/erp/styles/variables/variables.scss";



























































































































































































































































































































































































































































































































































































































































































.left-aside-wrapper {
  .icon-careful-full {
    color: red;
  }

  display: flex;
  overflow-y: scroll;

  .left-aside {
    overflow-y: scroll;

    ::v-deep {
      .el-tree-node__content {
        height: 36px;
      }

      .all-unRead {
        height: 36px;
        line-height: 36px;
        background-color: #fff;
        padding-left: 5px;
      }

      .el-collapse {
        .el-collapse-item {
          .el-collapse-item__header {
            padding-left: 5px;
            color: #303133;
            font-size: 14px;
            font-family: PingFang Bold;
          }

          .el-collapse-item__content {
            padding-bottom: 0px;
          }
        }
      }
    }

    .custom-tree-node {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding-right: 10px;

      .custom-tree-node-text {
        display: flex;
        width: 100%;
        height: 36px;
        align-items: center;
      }
    }

    .folederName {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      display: inline-block;
    }

    .unRead {
      color: #ff5757;
      margin-left: 10px;
    }
  }

  .mail-nav-item {
    line-height: 36px;
    font-size: 14px;
    color: #303030;
    cursor: pointer;
    padding-left: 14px;

    &.active {
      background: #f5f7fa;
      color: #0486fe;

      .iconfont {
        color: #0486fe;
      }
    }

    &:hover {
      background: #f5f7fa;
    }

    span {
      margin-left: 14px;
    }

    .warning-text {
      color: #e02020;
      margin-left: 0;
    }

    .iconfont {
      color: #999;
    }

    &.receive {
      padding: 10px;
      color: #999999;

      &.active {
        background: #f5f7fa;
        color: #303030;
      }

      .receive-header {
        text-align: left;
        text-indent: 0;
        padding-left: 4px;
        display: flex;
        justify-content: space-between;
        padding-right: 14px;

        .warning-text {
          color: #e02020;
          margin-left: 0;
        }

        &>div:first-child {
          i {
            font-size: 16px;
            padding: 0;
            line-height: 36px;
          }
        }

        &>div:nth-child(2) {
          i {
            font-size: 12px;
            margin-left: 13px;
          }
        }

        .icon-plus {
          display: none;
        }

        &:hover {
          .icon-plus {
            display: block;
          }
        }
      }

      .receive-add {
        display: flex;
        text-indent: 0;

        &>div:first-child {
          padding-left: 14px;
          box-sizing: border-box;
          display: flex;

          i {
            font-size: 16px;
            padding: 0;
            line-height: 36px;
            margin-right: 14px;
          }

          /deep/ .el-input__inner {
            height: 24px;
            padding: 0 4px !important;
          }
        }

        &>.sub-btns {
          flex: 1;
          display: flex;

          &>div {
            padding: 0 2px;
            width: 20px;

            i {
              line-height: 36px;
              font-size: 16px;
            }
          }
        }
      }

      .receive-main {
        padding-left: 10px;
      }
    }
  }

  .account-title {
    width: 100%;
    display: flex;
    padding-right: 15px;
    align-items: center;

    .flex1 {
      flex: 1;
    }

    .tag {
      color: #fff !important;
      padding: 0 4px;
      border-radius: 2px;
      border-color: transparent;
      height: 20px;
      line-height: 20px;

      &.tag1 {
        background-color: rgb(194, 128, 255);
      }

      &.tag2 {
        background-color: #7f7f7f;
      }
    }
  }
}
