@import "~@/erp/styles/variables/variables.scss";







































































































































































































































.dialog-content {
  max-height: 50vh;
  min-height: 10vh;
  overflow: hidden;
  overflow-y: auto;
}
