@import "~@/erp/styles/variables/variables.scss";



















































































































































































































































































































































































































































































































































































































.plan-order {
  .mb-10 {
    margin-bottom: 10px;
  }
  .container {
    /deep/.el-form {
      .el-form-item {
        margin-bottom: 0;
        &.is-error {
          margin-bottom: 18px;
        }
        .el-date-editor {
          width: 100%;
        }
        .el-form-item__error {
          z-index: 1000;
        }
      }
    }
  }
  ::v-deep {
    .el-form-item__error {
      margin-top: 0;
    }
    .el-date-editor .el-input__inner {
      height: 32px !important;
    }
  }
}
.red-pink {
  font-size: 12px;
}
