@import "~@/erp/styles/variables/variables.scss";






























































































































































































































































































































































































































.table-container {
  padding: 0 20px 20px !important;

  & .title {
    display: flex;
    align-items: center;
  }
}

.detail-title {
  margin-bottom: 15px;
}

.detail-label {
  font-size: 14px;
  color: #606266;
}

.detail-content {
  font-size: 16px !important;
  margin-top: 10px;
  font-weight: normal;
}

.header {
  font-size: 18px;
  color: #606266;
  //font-family: 'PingFang Bold';
}

.mr15 {
  margin-right: 15px;
}

.ml15 {
  margin-left: 15px;
}

.mt20 {
  margin-top: 20px;
}

.pointer {
  cursor: pointer;
}

.costTable {
  height: calc(100vh - 260px);
  overflow-y: auto;
}

::v-deep {
  .el-page-header {
    margin: 0 !important;
  }

  .el-pagination {
    height: 38px;
  }
}

.el-tooltip__popper {
  max-width: 400px;
}
