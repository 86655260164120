@import "~@/erp/styles/variables/variables.scss";
































































































































































































































































































































































































































































































































































.search-form {
  overflow-y: auto;
  margin-bottom: 80px;
  .search-form-content {
    padding: 0 20px;
    .el-form-item {
      span {
        color: #000;
      }
    }
  }
  .operate {
    position: fixed;
    width: 100%;
    bottom: 0;
    background: #fff;
    border-top: 1px solid #ededed;
    padding: 20px;
  }
}
.no-text {
  color: #9e9e9e;
}
.search_form .el-date-editor--datetimerange.el-input__inner {
  width: 100%;
}
::v-deep {
  .el-date-editor {
    width: 100%;
  }
  .el-divider {
    margin: 30px 0 28px 0 !important;
  }
  .el-drawer__body {
    height: calc(100% - 44px);
  }
  .el-form-item--small.el-form-item {
    margin-bottom: 15px;
  }
}
