@import "~@/erp/styles/variables/variables.scss";



































































































































































































































































































































































































.el-message-box.import-tip {
  width: 500px;
  .el-message-box__message p {
    word-break: break-all; // 单词可以截断
    white-space: pre-line; // 识别 \n
    max-height: 450px;
    overflow: auto;
  }
}
