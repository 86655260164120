@import "~@/erp/styles/variables/variables.scss";
































































































































































































































































































.card-item-box{
  width:100%;
  height:100%;
  .infinite-scroll {
    height: 100%;
    overflow-y: auto;
  }
}
.unread-mails-item {
  font-size: 14px;
  .mails-item--top {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    word-break: break-all;
    color: #000;
    .el-tag {
      margin-right: 10px;
    }
  }
  .mails-item--bottom {
    display: flex;
    font-size: 12px;
    justify-content: space-between;
    p {
      color: #5e5e5e;

      .label {
        margin-right: 5px;
      }
    }
  }
  .mr5 {
    margin-right: 5px;
  }
}
