@import "~@/erp/styles/variables/variables.scss";



































































































































































































































































































































































































































































































































































































































































































































































































































































































































.platform-product-details {
  .area {
    // border: 1px solid #e4e7ed;
    border-radius: 2px;
    margin-top: 20px;
    .height-text {
      color: #1890ff !important;
    }
    > h4 {
      // font-weight: normal;
      // padding-left: 20px;
      font-size: 14px;
      color: #606266;
      line-height: 40px;
      // border-bottom: 1px solid #e4e7ed;
      // background: #f5f7fa;
    }
    .el-row {
      margin: 20px 40px 20px 20px;
      &.margin0 {
        margin: 0;
      }
      .el-col {
        margin-top: 10px;
        display: flex;
        > span {
          &.label {
            display: inline-block;
            width: 160px;
            text-align: right;
            &.w200 {
              width: 200px;
            }
            &.w300 {
              width: 300px;
            }
            &.w400 {
              width: 400px;
            }
          }
          &.value {
            flex: 1;
            text-align: justify;
            word-break: break-all;
          }
        }
        .values {
          flex: 1;
          overflow: auto;
          .value {
            margin-right: 20px;
            margin-bottom: 10px;
            display: inline-block;
            width: 100px;
            line-height: 24px;
            padding: 0 10px;
            background-color: #f0f0f0;
          }
        }
      }
    }
    .el-divider {
      margin: 20px 0 10px;
    }
    .price-info {
      margin-top: 20px;
      > li {
        margin-top: 10px;
        .label {
          display: inline-block;
          width: 160px;
          text-align: right;
        }
        .value {
          margin-right: 20px;
          display: inline-block;
          min-width: 100px;
          line-height: 24px;
          padding: 0 10px;
          background-color: #f0f0f0;
        }
      }
    }
    .price-options {
      padding-left: 50px;
      .name {
        margin: 20px 0 10px;
        .value {
          display: inline-block;
          width: 200px;
          padding: 0 10px;
          line-height: 24px;
          background-color: #f0f0f0;
        }
      }
      .table-wrapper {
        display: flex;
        .tips {
          margin: 10px 20px 0;
          .value {
            margin-right: 20px;
            display: inline-block;
            width: 100px;
            line-height: 24px;
            padding: 0 10px;
            background-color: #f0f0f0;
          }
          .el-radio-group {
            margin-top: 20px;
            display: block;
            .el-radio {
              margin-right: 10px;
            }
            .el-tooltip {
              margin-right: 30px;
            }
          }
          ::v-deep .el-radio-group {
            .is-disabled {
              .el-radio__label {
                color: #606266;
              }
            }
            .el-radio__inner {
              &::after {
                background-color: #606266;
              }
            }
          }
        }
      }
    }
    .image-info {
      overflow-x: auto;
      margin: 20px;
      > li {
        display: flex;
        align-items: center;
        overflow: hidden;
        &:first-of-type {
          border-top: 1px solid #f0f0f0;
          > div {
            height: 120px;
          }
        }
        > div {
          flex: 1;
          height: 60px;
          border-right: 1px solid #f0f0f0;
          border-bottom: 1px solid #f0f0f0;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          &:first-of-type {
            border-left: 1px solid #f0f0f0;
            background-color: #f5f7fa;
          }
          &.value {
            padding: 10px;
            > img {
              width: 100%;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  li {
    list-style: none;
  }
  .prod_code {
    color: #409eff;
    text-decoration: underline;
    cursor: pointer;
  }
  .mb50 {
    margin-bottom: 50px;
  }
  .pb20 {
    padding-bottom: 20px;
  }
  .remark {
    margin: 20px 40px;
    white-space: pre-line;
  }
  ::v-deep.checkbox-disabled-highlight {
    &.is-disabled {
      .el-checkbox__inner {
        &::after {
          border-color: #606266;
        }
      }
      .el-checkbox__label {
        color: #606266;
      }
    }
  }
}
