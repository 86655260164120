@import "~@/erp/styles/variables/variables.scss";






































































































































.dialog-footer {
  display: flex;
  justify-content: center;
}
::v-deep {
  .el-checkbox-button {
    margin-right: 10px;
    .el-checkbox-button__inner {
      border-radius: 4px;
      overflow: hidden;
      border: 1px solid #1890ff;
    }
  }
  .select-user {
    .el-tag {
      & + .el-tag {
        margin-left: 2px;
      }
      .el-select__tags-text {
        max-width: 28px;
      }
    }
    .el-tag__close {
      display: none;
    }
  }
}
