@import "~@/erp/styles/variables/variables.scss";










































































































































































































































































































::v-deep {
  .el-table__header-wrapper .el-table__cell {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

.special-header {
  position: relative;

  span {
    display: inline-block;
    width: 50%;
    text-align: right;

    &.special-header--right {
      text-align: left;

      &::after {
        content: '';
        display: inline-block;
        width: 400px;
        height: 1px;
        background-color: #ebeef5;
        /* background-color: red; */
        position: relative;
        left: -124px;
        transform: rotate(11deg);
      }
    }
  }
}

.printing-area {
  position: relative;

  .mask {
    width: 582px;
    height: 582px;
    position: absolute;
    z-index: 999;
    background: transparent;
  }
}

::v-deep {
  .el-dialog__body {
    padding: 0 10px;
  }
}

.printing-surface-box {
  display: flex;
  align-items: center;

  .rendering-desc {
    padding: 0 10px;
    flex: 1;
    width: 0;

    ::v-deep .el-checkbox {
      display: flex;
      align-items: center;

      .el-checkbox__label {
        font-size: 14px;
        flex: 1;
        width: 0;
        word-break: break-all;
        white-space: normal;
      }

      .el-checkbox__input {
        margin-top: 2px;
      }
    }
  }
}
