@import "~@/erp/styles/variables/variables.scss";












































































































































































































































































































































































































































































































































































































































































































































.custom-dialog {
  .container {
    .add {
      margin: 15px 0;
      text-align: center;
      color: #409eff;
    }
    .img-wrap {
      position: relative;
      .el-image {
        width: 56px;
        height: 56px;
        object-fit: cover;
        display: block;
        margin: 0 auto;
        border-radius: 2px;
      }
      .el-badge {
        position: absolute;
        top: -4px;
        right: 12px;
        z-index: 2;
      }
    }
    .wrapper-box {
      text-align: center;
      position: relative;
      .wrapper-add {
        display: inline-block;
        width: 64px;
        height: 64px;
        line-height: 64px;
        text-align: center;
        border: 1px dotted #c0cbda;
        border-radius: 4px;
        cursor: pointer;
        i {
          font-size: 18px;
        }
      }
      .wrapper-image {
        img {
          width: 64px;
          height: 64px;
          margin-top: 8px;
          border-radius: 2px;
          object-fit: cover;
          cursor: pointer;
        }
      }
      .el-badge {
        position: absolute;
        top: 4px;
        right: 8px;
      }
    }
  }
}
::v-deep {
  .el-table td {
    padding: 0;
  }
  .el-form-item {
    margin: 0;
    padding: 14px 0;
  }
  .el-table table th.star div:before {
    content: '*';
    color: red;
  }
  .el-table__body-wrapper {
    height: 360px;
    overflow: scroll;
  }
}
