@import "~@/erp/styles/variables/variables.scss";






























































































































































































































































































































































































































































































































































































































































































































































.table-box {
  /deep/.el-table th > .cell {
    text-align: center;
  }
  /deep/.el-table .cell {
    text-align: center;
  }
}
