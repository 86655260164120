@import "~@/erp/styles/variables/variables.scss";










































































































































































































































































































































































































.oper-sticky {
  width: calc(100% - 105px);
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0;
  border-top: 1px solid #ccc;
  height: 80px;
  align-items: center;
  background-color: #fff;
}

.pro-button {
  border-color: rgb(40, 130, 255);
  color: rgb(40, 130, 255);
  margin: 15px 0;
}

.add-box {
  overflow: hidden;
  overflow-y: scroll;
  margin-bottom: 100px !important;

  height: calc(100vh - 145px) !important;
  .add-box-header {
    display: flex;
    align-items: center;
    .tip {
      margin-left: 40px;
      margin-bottom: 20px;
      color: red;
      font-size: 16px;
    }
  }
}
