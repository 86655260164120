@import "~@/erp/styles/variables/variables.scss";

































































































































































































































































































































.ticket-table{
  ::v-deep.el-table {
    .cell{
      padding: 0 2px;
      .el-form-item{
        margin: 14px 0;
        .el-input{
          .el-input__inner{
            text-align: center;
            padding: 0 4px;
          }
        }
        .colorRed{
          color: red;
          .el-input__inner{
            color: red;
          }
        }
      }
    }
  }
  .add-row{
    text-align: center;
    padding: 10px 0 30px;
    span{
      cursor: pointer;
      color: rgb(64, 158, 255);
    }
  }
}

