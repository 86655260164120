<template>
  <div class="table-container inbound">
    <!-- class="page-header align_center" type="flex" -->
    <el-row>
      <el-page-header @back="goBack" content="创建入库单"></el-page-header>
    </el-row>
    <h3 class="mb20">基本信息</h3>

    <div class="form-wrapper">
      <!-- 其他入库表单 -->
      <el-form
        ref="form"
        label-width="90px"
        key="typeIsQt"
        :model="form"
        :rules="rules"
      >
        <el-row>
          <el-col :span="6">
            <el-form-item label="入库方式" prop="type">
              <el-select
                v-model="currentType"
                value-key="type"
                disabled
                style="width: 100%"
              >
                <el-option
                  v-for="item in typeNames"
                  :key="item.type"
                  :value="item"
                  :label="item.typeName"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <el-form-item label="入库仓库" prop="warehouseId">
              <el-select
                v-model="form.warehouseId"
                value-key="type"
                style="width: 100%"
                :popper-append-to-body="false"
              >
                <el-option
                  v-for="item in warehouses"
                  :key="item.warehouseId"
                  :value="item.warehouseId"
                  :label="item.name"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="创建人" prop="creator">
              <el-input disabled v-model="form.creator"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="所属地区" prop="creatorArea">
              <el-input disabled v-model="form.creatorArea"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="操作人" prop="operationName">
              <el-input v-model="form.operationName" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :sapn="24">
            <el-form-item prop="remark">
              <span slot="label">备注</span>
              <el-input
                type="textarea"
                :rows="2"
                show-word-limit
                v-model="form.remark"
                placeholder="请输入备注,字数500字以内"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <!-- 入库产品 -->
    <h3 class="mt30 mb20">入库产品</h3>
    <p class="m20">
      <el-button
        @click="selectProduct"
        v-show="typeIsQt"
        style="border-color: #2882ff; color: #2882ff"
      >
        添加产品
      </el-button>
    </p>

    <!-- 退货入库表格 -->
    <el-table v-if="typeIsTh" border :data="tableData" class="mt20">
      <el-table-column
        align="center"
        prop="warehouseOutNumber"
        label="出库单号"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="skuId"
        label="SKU ID"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="productName"
        label="产品名称"
        width="200" show-overflow-tooltip
      ></el-table-column>
      <el-table-column align="center" prop="productSpecCn" label="英文规格" width="200" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.productSpec | emptyFilter }}
        </template>
      </el-table-column>

      <el-table-column align="center" prop="productSpecCn" label="中文规格" width="200" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.productSpecCn | emptyFilter }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="productType" label="产品类型">
        <template slot-scope="scope">
          {{ scope.row }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="productUnit" label="单位">
        <template slot-scope="scope">
          {{ scope.row.productUnit | emptyFilter }}
        </template>
      </el-table-column>
      <!-- 退库入库新加 -->
      <el-table-column align="center" prop="warehouseOutNum" label="已出库数量">
        <template slot-scope="scope">
          {{ scope.row.warehouseOutNum ? scope.row.warehouseOutNum : '--' }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="warehouseInNum" width="150px">
        <template slot="header">
          <span>入库数量</span>
        </template>
        <template slot-scope="scope">
          {{ scope.row.warehouseInNum ? scope.row.warehouseInNum : '--' }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="remark" label="备注" width="200px">
        <template slot-scope="scope">
          <el-tooltip
            v-if="scope.row.remark && scope.row.remark.length > 20"
            class="item"
            effect="light"
            :content="scope.row.remark"
            placement="top"
          >
            <el-input
              v-model="scope.row.remark"
              placeholder="请输入"
            ></el-input>
          </el-tooltip>
          <el-input
            v-model="scope.row.remark"
            placeholder="请输入"
            v-else
          ></el-input>
          <p
            v-if="scope.row.remark && scope.row.remark.length > 500"
            class="error-info"
          >
            备注长度不能超过500
          </p>
        </template>
      </el-table-column>
      <el-table-column align="center" label="入库库位"></el-table-column>

      <el-table-column align="center" label="操作">
        <template slot-scope="scope">
          <p class="operation">
            <el-tooltip
              class="item"
              effect="dark"
              content="添加库位"
              placement="top-start"
            >
              <el-button
                type="primary"
                plain
                icon="el-icon-map-location"
                circle
                size="mini"
                @click="addLoc(scope.row)"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="删除"
              placement="top-start"
            >
              <el-button
                type="danger"
                plain
                icon="el-icon-delete-solid"
                circle
                size="mini"
                @click="deletePro(scope.$index, scope.row.warehouseOutNumber)"
              ></el-button>
            </el-tooltip>
          </p>
        </template>
      </el-table-column>
    </el-table>

    <el-table v-if="typeIsQt" border :data="tableData" class="mt20">
      <el-table-column align="center" prop="skuId" label="产品编码" width="180" show-overflow-tooltip>
        <template slot-scope="scope">
          <el-link
            v-if="scope.row.skuId.indexOf('FL') === -1"
            type="primary"
            :underline="false"
            @click="skuIdClick(scope.row.skuId)"
          >
            {{ scope.row.skuId }}
          </el-link>
          <span v-else>{{ scope.row.skuId }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="productName"
        label="产品名称"
        width="200" show-overflow-tooltip
      ></el-table-column>
      <el-table-column align="center" prop="productSpec" label="英文规格" width="200" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.productSpec | emptyFilter }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="productSpecCn" label="中文规格" width="200" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.productSpecCn | emptyFilter }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="standardCustomized"
        label="产品类型"
      >
        <template slot-scope="scope">
          {{ scope.row.standardCustomized | emptyFilter }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="productUnit" label="单位">
        <template slot-scope="scope">
          {{ scope.row.productUnit | emptyFilter }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="warehouseInNum" width="150px">
        <template slot="header">
          <span style="color: red; vertical-align: middle">*</span>
          <span>实际入库数量</span>
        </template>
        <template slot-scope="scope">
          <el-input-number
            v-if="!scope.row.warehouseInNum && scope.row.warehouseInNum !== 0"
            v-model="scope.row.warehouseInNum"
            :max="99999999"
            :class="{ error: showError && !scope.row.warehouseInNum }"
            @change="onChangeWarehouseInNum(scope.row)"
            @blur="checkEmpty(scope.row, 'warehouseInNum')"
            :precision="0"
          ></el-input-number>
          <el-input-number
            v-else
            :min="0"
            v-model="scope.row.warehouseInNum"
            :max="99999999"
            :class="{ error: showError && !scope.row.warehouseInNum }"
            @change="onChangeWarehouseInNum(scope.row)"
            @blur="checkEmpty(scope.row, 'warehouseInNum')"
            :precision="0"
          ></el-input-number>
          <p
            v-if="
              scope.row.warehouseInNumEmptyCheck && !scope.row.warehouseInNum
            "
            class="error-info"
          >
            为正数,不能为空或0
          </p>
        </template>
      </el-table-column>

      <el-table-column align="center" prop="priceExcludingTax" width="130px">
        <template slot="header">
          <span style="color: red; vertical-align: middle">*</span>
          <span>{{ typeIsCg ? '不含税单价' : '入库单价' }}</span>
        </template>
        <template slot-scope="scope">
          <el-input-number
            :precision="3"
            :min="0"
            :max="99999999"
            :class="{ error: showError && !scope.row.priceExcludingTax }"
            @change="onChangePriceExcludingTax(scope.row)"
            @blur="checkEmpty(scope.row, 'priceExcludingTax')"
            v-model="scope.row.priceExcludingTax"
          ></el-input-number>
          <p
            v-if="
              scope.row.priceExcludingTaxEmptyCheck &&
              !scope.row.priceExcludingTax
            "
            class="error-info"
          >
            为正数,不能为空或0
          </p>
        </template>
      </el-table-column>

      <el-table-column
        align="center"
        :label="typeIsCg ? '不含税成本' : '入库成本'"
        prop="totalPriceExcluding"
      >
        <template slot-scope="scope">
          {{ toFixed3(scope.row.totalPriceExcluding) }}
        </template>
      </el-table-column>

      <el-table-column align="center" prop="remark" label="备注" width="200px">
        <template slot-scope="scope">
          <el-tooltip
            v-if="scope.row.remark && scope.row.remark.length > 20"
            class="item"
            effect="light"
            :content="scope.row.remark"
            placement="top"
          >
            <el-input
              v-model="scope.row.remark"
              placeholder="请添加备注"
            ></el-input>
          </el-tooltip>
          <el-input
            v-model="scope.row.remark"
            placeholder="请添加备注"
            v-else
          ></el-input>
          <p
            v-if="scope.row.remark && scope.row.remark.length > 500"
            class="error-info"
          >
            备注长度不能超过500
          </p>
        </template>
      </el-table-column>
      <el-table-column align="center" label="入库库位">
        <template slot-scope="scope">
          <SelectedLocs
            :locs="scope.row.warehouseInProductLocationDTOList"
            @add-loc="addLoc"
            :tableIndex="scope.$index"
          />
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作">
        <template slot-scope="scope">
          <p class="operation">
            <el-tooltip
              class="item"
              effect="dark"
              content="删除"
              placement="top-start"
            >
              <el-button
                type="danger"
                plain
                icon="el-icon-delete-solid"
                circle
                size="mini"
                @click="deletePro(scope.$index)"
              ></el-button>
            </el-tooltip>
          </p>
        </template>
      </el-table-column>
    </el-table>

    <h3 class="mt30 mb20">相关附件</h3>
    <ErpUpload
      ref="ErpUpload"
      :uploadParams="uploadParams"
      @uploadList="uploadList"
      @initUpload="initUpload"
      style="margin-bottom: 40px; background: #e4e7ed; padding: 15px"
    ></ErpUpload>
    <p class="divider"></p>
    <div style="text-align: center">
      <el-button type="primary" @click="onSubmit" :loading="isLoading">
        确认
      </el-button>
      <el-button @click="$router.back()" class="ml15">取消</el-button>
    </div>
    <!-- 产品列表 -->

    <el-dialog
      width="1100px"
      title="选取产品"
      :visible.sync="productListVisible"
      :close-on-click-modal="false"
      append-to-body
    >
      <ProductDialg
        v-if="productListVisible"
        ref="product"
        :selectProductList="tableData"
        :purchaseDemandAddFlag="0"
        @getProductData="getProductData"
        @productClose="productClose"
        :columnsHidden="columnsHidden"
      ></ProductDialg>
    </el-dialog>

    <!-- 库位列表 -->
    <Loc
      ref="loc"
      :locList="locList"
      :params="locParams"
      :warehouseInNum="currentRow.warehouseInNum"
      :warehouseId="form.warehouseId"
      @set-loc="onSetLoc"
      @close="onClose('loc')"
    ></Loc>

    <!-- 审核选择 -->
    <AuditChoose ref="AuditChoose" @auditData="saveAuditClick" />
  </div>
</template>

<script>
  import { InboundSheet } from '@/core'
  import { storeManage } from '@/core/interactors/stock-center/stock-manage'
  import globalBackButtonMixin from '@/utils/global-back-button'
  import { mapGetters } from 'vuex'
  import ProductDialg from '@/views/purchase/purchaseDemand/components/productList.vue'
  import Loc from './components/loc.vue'
  import SelectedLocs from './components/selected-locs.vue'
  import AuditChoose from '@/components/AuditChoose'
  import { getLabels } from '@/utils/specs-utils'
  const baseProduct = {
    skuId: '',
    productName: '',
    productSpec: '',
    productType: '',
    productUnit: '',
    contractNum: '',
    warehouseInNum: '',
    giftNum: '',
    priceExcludingTax: '',
    priceIncludingTax: '',
    totalPriceExcluding: '0.000',
    totalPriceIncluding: '0.000',
    remark: '',
    productImage: '',
    productCode: '',
    warehouseInProductLocationDTOList: [], //入库库位信息
  }
  let self,
    rules = {
      type: [
        {
          required: true,
          message: '请选择入库方式',
        },
      ],
      warehouseId: [
        {
          required: true,
          message: '请选择入库仓库',
          trigger: 'change',
        },
      ],

      purchaseOrderNumber: [
        {
          required: true,
          message: '请输入采购单号',
          trigger: 'blur',
        },
      ],
      orderNumber: [
        {
          required: true,
          message: '请输入订单编号',
          trigger: 'blur',
        },
      ],
      warehouseOutNumber: [
        {
          required: true,
          message: '请选择出库单号',
          trigger: 'change',
        },
      ],
      remark: [
        {
          validator: (rule, value, callback) => {
            if (value && value.length > 500) {
              return callback(new Error('备注长度不能超过500'))
            }
            callback()
          },
        },
      ],
    }
  export default {
    name: 'new-inbound-sheet',
    data() {
      self = this
      return {
        columnsHidden: false,
        uploadParams: {
          size: 1024 * 1024 * 20, //单个文件上传大小
        },
        form: {
          type: 2, //入库方式code
          typeName: '', // 入库方式名称
          warehouseId: '', //入库仓库
          operationId: '',
          operationName: '',
          remark: '',
          orderNumber: '', //订单编号
          warehouseInId: '',
          purchaseOrderNumber: '', //采购单号
          supplierName: '',
          supplierId: '',
          warehouseInNumber: '',
          warehouseOutNumber: '',
        },
        showError: false,
        selectedPro: [], //已选择产品
        rules,
        operation: {}, //登录人
        typeNames: [
          // {
          //   type: 0,
          //   typeName: '采购入库',
          // },
          // {
          //   type: 1,
          //   typeName: '退货入库',
          // },
          {
            type: 2,
            typeName: '其他入库',
          },
        ], //入库方式
        currentType: {
          type: 2,
          typeName: '其他入库',
        }, //选中入库方式
        warehouses: [], //仓库列表
        params: {
          show: false,
        },
        locParams: {
          show: false,
        },
        tableData: [],
        currentRow: {},
        purchaseOrder: {
          contractArea: '',
          supplierName: '',
          purchaseOrderNumber: '',
        },
        files: '',
        isLoading: false,
        showEmptyError: false,
        warehouseOutNumberList: [], //出库单号下拉
        warehouseOutNumber: '',
        locList: [], //其他入库库位号下拉数据
        productListVisible: false,
      }
    },
    watch: {},

    components: {
      ProductDialg,
      Loc,
      AuditChoose,
      SelectedLocs,
    },

    computed: {
      warehouseName() {},
      ...mapGetters({
        userInfo: 'user/userInfo',
      }),
      tenantId() {
        return this.userInfo.tenantId
      },
      //采购入库
      typeIsCg() {
        return this.form.type === 0
      },
      //退货入库
      typeIsTh() {
        return this.form.type === 1
      },
      //其他入库
      typeIsQt() {
        return this.form.type === 2
      },
      isEdit() {
        return !!this.$route.query.warehouseInId
      },
      flag() {
        let currentWh = this.warehouses.find(
          (item) => item.warehouseId === this.form.warehouseId
        )
        if (currentWh) {
          return currentWh.flag
        } else {
          return 0
        }
      },
    },

    mixins: [globalBackButtonMixin],
    created() {
      this.initData()
      InboundSheet.findWarehouseList({
        hasAuth: 0,
        hasCloudWarehouse: 1,
      }).then((res) => {
        this.warehouses = res.data || []
      })
      if (this.isEdit) {
        // bugfix 编辑时数据未展示
        this.isReset = false
        const { warehouseInId } = this.$route.query
        InboundSheet.getDetail({ warehouseInId }).then((res) => {
          if (res.code == '000000') {
            Reflect.ownKeys(this.form).forEach((key) => {
              self.form[key] = res.data[key]
            })
            // bugfix 回显采购单号
            if (res.data.type === 0) {
              this.form.purchaseOrderNumber = res.data.orderNumber
            }
            if (this.form.orderNumber) {
              this.form.warehouseOutNumber =
                this.form.warehouseOutNumber.split(',')
              this.orderNumberSearch('edit')
            }

            Array.isArray(res.data.warehouseInProductVOList) &&
              (self.tableData = res.data.warehouseInProductVOList.map(
                (item) => {
                  item.warehouseInProductLocationDTOList =
                    item.warehouseInProductLocationVOList

                  //编辑-已出库
                  item.warehouseOutNum = item.contractNum

                  //编辑其他出库-产品类型
                  item.standardCustomized = item.productType

                  delete item.warehouseInProductLocationVOList
                  return item
                }
              ))

            this.currentType = this.typeNames.find(
              (item) => item.type == res.data.type
            )
            this.files = res.data.fileUrls
            if (res.data.fileUrls) this.initUpload(res.data.fileUrls)
          }
        })
      }

      // if (this.userInfo.userId) {
      //   this.$nextTick(() => {
      //     this.form.creatorArea = this.userInfo.area
      //     this.form.creator = this.userInfo.userName
      //   })
      // }
    },
    methods: {
      formatSelectProduct(list) {
        const __selectIds = []

        for (let i = 0; i < list.length; i++) {
          __selectIds.push(list[i].sku)
        }
        return __selectIds
      },

      // 选择产品
      selectProduct() {
        this.selectProductList = this.formatSelectProduct(this.tableData)
        this.productListVisible = true
      },

      // 关闭选择产品
      productClose() {
        this.productListVisible = false
      },
      getProductData(data = [], data2 = []) {
        data.forEach(
          (item) => {
            const {label:productSpec, labelCn: productSpecCn} = getLabels(item)
            item.productSpec = productSpec
            item.productSpecCn = productSpecCn

          }


        )
        data2.forEach((item) => (item.standardCustomized = '辅料'))
        let self = this
        let arr = []
        data = data.concat(data2)
        data.forEach((item) => {
          if (
            !self.selectProductList.includes(
              item.skuId || item.accessoriesNumber
            )
          ) {
            arr.push(item.skuId || item.accessoriesNumber)
          }
        })
        let _arr = data.map((item) => {
          return {
            ...baseProduct,
            ...item,
            skuId: item.sku || item.skuId || item.accessoriesNumber,
            productName: item.nameCn,
            productType: item.standardCustomized, //产品类型
            priceExcludingTax: 0,
            productImage: item.image || item.imgMain || item.skuImage || item.productImg,
            usable: 1,
          }
        })
        this.tableData = this.tableData.concat(_arr)
        self.productListVisible = false
      },
      // formatColumn(type, value1, value2) {
      //   if (type) {
      //     const arr = type.split(',')
      //     if (!arr[0]) {
      //       return '/'
      //     } else if (arr[0] && arr.length == 1) {
      //       return arr + ':' + value1
      //     } else {
      //       return arr[0] + ':' + value1 + '，' + arr[1] + ':' + value2
      //     }
      //   } else {
      //     return ''
      //   }
      // },
      selectPro(val) {
        // this.selectPro = val
        val.forEach((item) => {
          if (!this.tableData.find((pro) => pro.skuId === item.skuId)) {
            this.tableData.push({
              ...baseProduct,
              ...item,
              productName: item.nameCn,
              productType: item.standardCustomized, //产品类型
              priceExcludingTax: 0,
              productImage: item.image || item.imgMain || item.skuImage,
            })
          }
        })
      },
      //产品编码click
      skuIdClick(skuId) {
        this.getProductIdByCode(skuId.split('-')[0])
      },

      //根据产品code，查询产品id
      async getProductIdByCode(id) {
        let response = await storeManage.productIdByCode(id)
        if (response.code === '000000') {
          let routeData = this.$router.resolve({
            path: '/product/ProductView',
            query: {
              proId: response.data,
              noReturn: true,
            },
          })
          window.open(routeData.href, '_blank')
        }
      },
      goBack() {
        this.$router.push('/stock-center/inbound-sheet/inbound-sheet')
      },
      //根据订单编号 查询出库单号

      async orderNumberSearch(str) {
        if (this.form.warehouseId === '') {
          return this.$baseMessage(
            '请先选择入库仓库',
            'warning',
            false,
            'erp-hey-message-warning'
          )
        }
        if (this.form.orderNumber === '') {
          return this.$baseMessage(
            '请输入订单编号',
            'warning',
            false,
            'erp-hey-message-warning'
          )
        }
        let response = await InboundSheet.getWarehouseOutNumberByOrderNumber({
          orderNumber: this.form.orderNumber,
          warehouseId: this.form.warehouseId,
        })
        if (response.code === '000000') {
          if (response.data && response.data.length) {
            this.warehouseOutNumberList = response.data
            if (str === 'search') {
              this.form.warehouseOutNumber = ''
              this.tableData = []
            }
          }
          if (response.data.length === 0) {
            this.warehouseOutNumberList = []
            this.form.warehouseOutNumber = ''
            this.tableData = []
            return this.$baseMessage(
              '该销售单号下暂无产品可入库',
              'warning',
              false,
              'erp-hey-message-warning'
            )
          }
        }
      },
      //出库单号选择
      warehouseOutNumberChange(val) {
        if (val) {
          let tableData = []
          this.warehouseOutNumberList.forEach((item, index) => {
            val.forEach((item2, index2) => {
              if (item.warehouseOutNumber === item2) {
                item.warehouseOutProductInVOS.forEach((item3) => {
                  tableData.push(item3)
                })
              }
            })
          })
          this.tableData = tableData
          //退货出库-已出库书数量需与实际入库数量相等
          this.tableData.forEach((item, index) => {
            item.warehouseInNum = item.warehouseOutNum
            item.priceExcludingTax = item.price
            item.totalPriceExcluding = Number(item.totalPrice)
          })
        }
      },
      //订单编号清空
      orderNumberChange(val) {
        if (!val) {
          this.form.warehouseOutNumber = ''
          this.warehouseOutNumberList = []
          this.tableData = []
        }
      },
      checkEmpty(row, field) {
        if (
          row[field] === 0 ||
          row[field] === '' ||
          typeof row[field] === 'undefined'
        ) {
          // this.showEmptyError = true
          if (!row[field + 'emptyCheck']) {
            this.$set(row, [field + 'EmptyCheck'], true)
          }
        }
      },
      $_checkCount() {
        let checResult = true
        let tableData = this.tableData
        for (let i = 0, l = tableData.length; i < l; i++) {
          let pro = tableData[i]

          let { warehouseInProductLocationDTOList, warehouseInNum, remark } =
            pro

          if (
            !warehouseInProductLocationDTOList ||
            warehouseInProductLocationDTOList.length === 0
          ) {
            //step01 校验是否选择出库库位
            checResult = false
            this.$message.warning(`请选择产品 ${pro.productName}的入库库位`)
            break
          } else {
            //step02 校验实际入库数和库位选择总数是否相同
            let totalLocNum = warehouseInProductLocationDTOList
              .map((i) => i.num)
              .reduce((prev, next) => prev + next)
            if (warehouseInNum !== totalLocNum) {
              this.$message.warning(
                `产品 ${pro.productName}的实际入库数量${warehouseInNum}和库位总放置数量${totalLocNum}不相等`
              )
              checResult = false
              break
            }
            if (remark && remark.length > 500) {
              checResult = false
              break
            }
          }
          // return checResult
        }
        return checResult
      },
      addPro() {
        this.params.show = true
        this.selectedPro = this.tableData
      },
      uploadList(fileList) {
        this.files = JSON.stringify(fileList)
      },

      execPurchaseOrderData(productList) {
        const tableData = []
        productList.forEach((item) => {
          let _basePro = { ...baseProduct, ...item }
          _basePro.skuId = item.sku
          _basePro.productName = item.nameCn
          _basePro.productSpec = item.specsValue1
          _basePro.productType = item.standardCustomized
          _basePro.contractNum = item.numQualified
          _basePro.productSpec = item.specsValue1
          // _basePro.priceExcludingTax = item.priceExcludingTax
          // _basePro.priceIncludingTax = item.priceIncludingTax
          _basePro.productImage = item.image || item.imgMain
          _basePro.productCode = item.productCode
          _basePro.orderProductId = item.businessId
          tableData.push(_basePro)
        })
        this.tableData = tableData
      },
      onChangeWarehouseInNum(row) {
        this.onChangePriceExcludingTax(row)
        this.onChangePriceIncludingTax(row)
      },
      onChangePriceExcludingTax(row) {
        row.totalPriceExcluding = this.toFixed3(
          this.BigMath.multiply(row.priceExcludingTax, row.warehouseInNum)
        )
      },
      onChangePriceIncludingTax(row) {
        if (this.typeIsCg) {
          row.totalPriceIncluding = this.toFixed3(
            this.BigMath.multiply(row.priceIncludingTax, row.warehouseInNum)
          )
        }
      },
      searchPurchaseOrder() {
        let { purchaseOrderNumber } = this.form
        if (!purchaseOrderNumber) {
          return this.$message.warning('请输入采购单号!')
        }
        InboundSheet.getDetailForShowByNumber(purchaseOrderNumber)
          .then((res) => {
            if (res && res.code === '000000') {
              this.form.supplierId = res.data.supplierId
              this.form.supplierName = res.data.supplierName
              this.execPurchaseOrderData(
                res.data.purchaseProductForStorageVOList || []
              )
            } else {
              this.form.purchaseOrderNumber = ''
            }
          })
          .catch(() => {
            this.form.purchaseOrderNumber = ''
          })
        // todo 调用接口获取数据
      },
      getSummaries(param) {
        const { columns, data } = param
        const sums = []
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = '总计'
            return
          }

          //采购入库 5 6 7 10 11
          //退货入库 6 7
          //其他入库 5 6
          let summayIndexs = []
          if (this.typeIsCg || this.typeIsTh) {
            summayIndexs = [6, 7]
          }
          //退货入库
          if (this.typeIsTh) {
            summayIndexs = [6, 7]
          }
          //其他入库
          if (this.typeIsQt) {
            summayIndexs = [5, 6]
          }
          // else {
          //   summayIndexs = [5, 6, 7, 9]
          // }
          // const summayIndexs = this.typeIsCg ? [5, 6, 7, 10, 11] : [5, 6, 7, 9]

          if (!summayIndexs.includes(index)) {
            sums[index] = ''
            return
          }
          const values = data
            .map((item) => Number(item[column.property]))
            .filter((val) => val || val === 0)

          if (values.length > 0) {
            sums[index] = values.reduce((prev, next) => {
              if ([5, 6, 7].includes(index)) {
                return prev + next
              } else {
                return this.BigMath.add(prev, next)
              }
            })
          } else {
            sums[index] = 0
          }
          if (index == 11 || index == 9 || index == 10) {
            sums[index] = this.toFixed3(sums[index])
          }

          // if (index == 5 && sums[index] === 0) {
          //   sums[index] = '--'
          // }
        })
        return sums
      },
      removeArray(arr, val) {
        for (var i = 0; i < arr.length; i++) {
          if (arr[i] == val) {
            arr.splice(i, 1)
            break
          }
        }
      },
      deletePro(index, warehouseOutNumber) {
        this.$confirm('是否确认删除？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(async () => {
          this.tableData.splice(index, 1)
          if (warehouseOutNumber) {
            this.removeArray(this.form.warehouseOutNumber, warehouseOutNumber)
          }
        })
      },
      onSetLoc(locs, warehouseInNum) {
        if (!this.currentRow.warehouseInProductLocationDTOList) {
          this.$set(this.currentRow, 'warehouseInProductLocationDTOList', locs)
        } else {
          this.currentRow.warehouseInProductLocationDTOList = locs
        }

        this.currentRow.warehouseInNum = warehouseInNum
        this.onChangeWarehouseInNum(this.currentRow)
      },
      addLoc(index) {
        let row = this.tableData[index]

        this.currentRow = row
        if (!this.form.warehouseId) {
          return this.$message.warning('请选择仓库')
        }
        // && row.warehouseInNum !== 0
        if (!row.warehouseInNum) {
          return this.$message.warning('请输入实际数量')
        }

        InboundSheet.findWarehouseLocation({
          flag: this.flag,
          skuId: row.skuId,
          warehouseId: this.form.warehouseId,
        }).then((res) => {
          if (res && res.code === '000000') {
            let locs = res.data || []
            this.locList = locs.map((loc) => {
              loc.currentNum = loc.num
              loc.locationNumber = loc.fullLocationNumber
              return loc
            })
          }
        })
        this.locParams.show = true
        // fixbug
        this.$nextTick().then(() => {
          if (
            self.currentRow.warehouseInProductLocationDTOList &&
            self.currentRow.warehouseInProductLocationDTOList.length > 0
          ) {
            self.$refs.loc.locs = JSON.parse(
              JSON.stringify(self.currentRow.warehouseInProductLocationDTOList)
            )
          } else {
            self.$refs.loc.locs = [
              {
                locationNumber: '',
                num: '',
                currentNum: 0,
                warehouseId: '',
              },
            ]
          }
        })
      },
      onClose(isLoc) {
        if (isLoc) {
          return (this.locParams.show = false)
        }
        this.params.show = false
      },

      $_checkProduct() {
        this.showError = true

        var checkFields = [
          'warehouseInNum',
          'priceExcludingTax',
          // 'priceExcludingTax',
        ]
        let tableData = this.tableData
        tableData.forEach((item) => {
          if (this.typeIsCg || this.typeIsQt) {
            let _checkFields = checkFields.slice(0, 1)
            _checkFields.forEach((field) => {
              if (!item[field + 'EmptyCheck']) {
                this.$set(item, [field + 'EmptyCheck'], true)
              }
            })
          } else {
            // let _checkFields = checkFields.slice(0, 2)
            checkFields.forEach((field) => {
              if (!item[field + 'EmptyCheck']) {
                this.$set(item, [field + 'EmptyCheck'], true)
              }
            })
          }
        })
        let empty = this.tableData.find((item) => {
          if (this.typeIsCg || this.typeIsTh) {
            return !item.warehouseInNum
            // ||
            // !item.priceExcludingTax ||
            // !item.priceIncludingTax
          } else {
            return !item.warehouseInNum || !item.priceExcludingTax
          }
        })
        return empty
      },

      //提交审核
      saveAuditClick(auditData) {
        this.isLoading = true
        auditData.orderId = this.$route.query.warehouseInId
        const {
          operationId,
          operationName,
          remark,
          warehouseId,
          warehouseInId,
          supplierName,
          supplierId,
          purchaseOrderNumber,
          orderNumber,
          creatorArea,
          creator,
        } = this.form

        let tableData = this.tableData.map((item, index) => {
          item.contractNum = item.warehouseOutNum
          item.productImage = item.image || item.imgMain || item.skuImage
          return item
        })
        const { type, typeName } = this.currentType
        const requestData = {
          fileUrls: this.files,
          operationId,
          operationName,
          creatorArea,
          creator,
          // warehouseOutNumber: this.form.warehouseOutNumber.join(',') || '',
          orderNumber: orderNumber || purchaseOrderNumber, // 有orderNumber先取orderNumber
          remark,
          supplierName,
          supplierId,
          type,
          typeName,
          warehouseId,
          warehouseName: this.warehouses.find(
            (item) => this.form.warehouseId === item.warehouseId
          ).name,
          warehouseInId,
          warehouseInProductDTOList: tableData,
          createWay: 0,
          auditObjectDTO: auditData,
          area: this.userInfo.area,
        }
        //退货入库-出库单号
        if (this.typeIsTh) {
          requestData.warehouseOutNumber =
            this.form.warehouseOutNumber.join(',') || ''
        }
        if (this.isEdit) {
          //处理编辑任务参数
          requestData.warehouseInProductDTOList &&
            requestData.warehouseInProductDTOList.forEach((item) => {
              item.warehouseInProductLocationDTOList.forEach((j) => {
                j.warehouseInProductId = item.warehouseInProductId
                j.warehouseId = requestData.warehouseId
                j.skuId = item.skuId
              })
            })
          requestData.tenantId = this.tenantId
          requestData.warehouseInNumber = this.form.warehouseInNumber
        }
        const requestMethod = this.isEdit
          ? InboundSheet.updateWarehouseIn
          : InboundSheet.insertWarehouseIn
        requestMethod(requestData).then((res) => {
          this.isLoading = false
          if (res && res.code === '000000') {
            this.$message.success(
              this.isEdit ? '更新入库单成功' : '新增入库单成功'
            )
            this.$router.back()
          }
        })
      },

      async onSubmit() {
        let flag = false
        this.$refs.form.validate((validate) => {
          if (validate) {
            flag = true
          }
        })
        if (!flag) {
          return
        }
        if (!this.tableData || this.tableData.length === 0) {
          return this.$message.warning('请选择产品')
        }
        if (this.$_checkProduct()) {
          return
        }
        if (!this.$_checkCount()) {
          return
        }
        this.isLoading = true

        let params = {
          auditType: 4,
          status: 0,
          tenantId: this.userInfo.tenantId,
        }
        this.pubApi.getAuditTemplates(params).then((response) => {
          this.isLoading = false
          if (!response.data.length) {
            return this.$baseMessage(
              '请联系管理员配置审核模板',
              'warning',
              false,
              'erp-hey-message-warning'
            )
          } else {
            this.$refs['AuditChoose'].showAddEdit(params)
          }
        })
      },
      initData() {
        this.currentType = this.typeNames[0]
        this.form.operationName = this.userInfo.userName
        this.form.operationId = this.userInfo.userId
        this.form.creatorArea = this.userInfo.area
        this.form.creator = this.userInfo.userName
      },

      resetFormAndTableDataWhenTypeChanged() {
        Reflect.ownKeys(this.form).forEach((key) => {
          if (
            key !== 'type' &&
            key !== 'operationName' &&
            key !== 'warehouseInId' &&
            key != 'warehouseInNumber' &&
            key != 'creatorArea' &&
            key != 'creator'
          ) {
            this.form[key] = ''
          }
        })
        this.tableData = []
      },
      resetFormAndTableDataWhenWHChanged() {
        Reflect.ownKeys(this.form).forEach((key) => {
          if (
            key !== 'type' &&
            key !== 'operationName' &&
            key !== 'warehouseId' &&
            key !== 'warehouseInId' &&
            key != 'warehouseInNumber' &&
            key != 'creatorArea' &&
            key != 'creator'
          ) {
            this.form[key] = ''
          }
        })
        this.tableData = []
      },
      initUpload(data) {
        this.$refs.ErpUpload.initUpload(data)
      },
    },

    filters: {
      emptyFilter(val) {
        return val ? val : '--'
      },
    },
  }
</script>

<style scoped lang="scss">
  .page-header {
    height: 33px;
  }

  ::v-deep {
    .page-header {
      .el-form-item {
        margin-bottom: 0;
      }
    }
  }
  .divider {
    height: 1px;
    background-color: #f5f7fa;
    margin: 9px 0 15px 0;
  }

  .mb30 {
    margin-bottom: 30px;
  }
  .mt30 {
    margin-top: 30px;
  }

  .mb20 {
    margin-bottom: 20px;
  }
  .mt20 {
    margin-top: 20px;
  }
  .mb15 {
    margin-bottom: 15px;
  }
  .mt15 {
    margin-top: 15px;
  }
  .table-container {
    padding: 0 15px 20px !important;
  }
  .operation {
    display: flex;
    justify-content: space-evenly;
    i {
      font-size: 20px;
    }
  }
  .pointer {
    cursor: pointer;
  }
  .error-info {
    color: #ff4d4f;
    font-size: 12px;
    line-height: 1;
    position: relative;
    padding-top: 5px;
  }

  ::v-deep .el-input-number--small {
    width: 100px !important;
  }
  ::v-deep .el-input-number--small .el-input__inner {
    padding: 0 10px !important;
  }
  ::v-deep .el-input-number--small .el-input-number__increase,
  ::v-deep .el-input-number--small .el-input-number__decrease {
    display: none !important;
  }
  ::v-deep .el-input-number--small.error .el-input__inner {
    border-color: #ff4d4f;
  }
</style>

<style>
  .inbound .el-select-dropdown__item {
    width: 200px;
  }
  .el-tooltip__popper {
    max-width: 400px;
  }
</style>
