@import "~@/erp/styles/variables/variables.scss";



























































































































































































.ticket-info{
  padding: 20px 0;
  .query-form-heard{
    .el-col{
        padding: 0 20px;
      }
   }  
}
