@import "~@/erp/styles/variables/variables.scss";

































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.tag-wrap {
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 20px; // 添加顶部边距，为tag腾出空间

  .el-tag {
    position: absolute;
    top: -16px; // 调整tag的位置，避免被截断
    right: 0;
    z-index: 2;
    max-width: 100%; // 确保tag不会超出容器宽度
    overflow: hidden; // 处理超长文本
    text-overflow: ellipsis; // 超长文本显示省略号
    white-space: nowrap; // 防止文本换行
  }

  .el-image {
    width: 50px;
    height: 50px;
    border-radius: 2px;
  }
}

.total-money {
  line-height: 40px;
  text-align: right;

  span {
    font-family: 'PingFang Bold';
    font-size: 18px;
    margin-left: 5px;
  }
}

.table-container {
  display: flex;
  justify-content: space-between;

  .l-table {
    width: 69%;
    height: min-content;
  }

  .r-table {
    width: 30%;
    height: min-content;
  }
}

.public-table {
  border: 1px solid #ebeef5 !important;
}

.line-style {
  display: flex;
  justify-content: flex-start;
  height: 32px;
  line-height: 32px;
  margin-top: 20px;
}

.tl {
  text-align: left;
}

.fileAnnex-span {
  display: inline-block;
  width: 15px;
  height: 15px;
  line-height: 15px;
  text-align: center;
  background: #9e9e9e;
  color: #fff !important;
  border-radius: 50%;
  margin-left: 5px;
  font-size: 12px;
}
