@import "~@/erp/styles/variables/variables.scss";

















































































































































































































































































































































































































































































































.header-main-title {
  color: #555;
  font-size: 18px;
}
.header-sub-title {
  color: #666;
  font-size: 14px;
  margin-left: 20px;
}

::v-deep {
  .is-active {
    background-color: #fff !important;
  }
  .select-user {
    .el-tag {
      & + .el-tag {
        margin-left: 2px;
      }
      .el-select__tags-text {
        max-width: 28px;
      }
    }
    .el-tag__close {
      display: none;
    }
  }
}
.time-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  > i {
    margin-left: 20px;
    cursor: pointer;
    color: #ff4d4f;
  }
}
.weekDayHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  > i {
    color: #ff4d4f;
    &:last-of-type {
      cursor: pointer;
      font-size: 18px;
      margin-left: 10px;
    }
  }
}
.table-title {
  margin: 10px 0;
  height: 35px;
  line-height: 35px;
  padding-left: 15px;
  background-color: rgba(242, 242, 242, 1);
  color: #333333;
}
.duty-form-wrapper {
  height: 800px;
  max-height: 90vh;
  overflow-y: auto;
}
::v-deep {
  .duty-drawer .el-drawer__body {
    padding: 10px;
  }
  .duty-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: hidden;
    .duty-form-wrapper {
      flex: 1;
      overflow-y: auto;
    }
  }
  .dialog-footer {
    display: flex;
    justify-content: center;
    height: 42px;
    padding-top: 10px;
    div {
      text-align: center;
    }
  }
}
