@import "~@/erp/styles/variables/variables.scss";









































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.pro-container {
  .profitRate {
    &::after {
      content: '%';
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
    }

    .el-input__inner {
      padding: 0 18px 0 15px;
    }
  }

  .left-h6 {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-family: 'PingFang Bold';
  }

  .left-h6::before {
    content: '';
    display: block;
    height: 16px;
    width: 2px;
    background: #282c34;
    margin-right: 5px;
    margin-top: 4px;
  }

  .right-content {
    height: 100%;
    border: 1px solid #e4e7ed;
    padding: 20px !important;
    border-radius: 3px;

    ::v-deep.el-form-item__content {
      width: 140px;
    }

    .proofing-day {
      ::v-deep.el-form-item__content {
        width: 280px;

        .el-input {
          width: 140px;
        }

        label: {
          display: inline-block;
          width: 120px;
        }
      }
    }

    .packing-size {
      ::v-deep.el-form-item__content {
        width: 100px;
      }
    }

    .SupportProofing {
      ::v-deep.el-form-item__content {
        width: 70px;
      }
    }

    .table-box {
      ::v-deep.el-table {
        .el-form-item__content {
          width: auto;
        }
      }
    }

    .calcu {
      position: relative;
      padding-bottom: 40px;
      padding-right: 180px;

      .calcu-cont-rate {
        display: flex;
        position: absolute;
        bottom: 10px;
        left: 90px;
      }

      ::v-deep .el-form-item {
        .el-form-item__content {
          width: 123px;

          .el-button--primary {
            background-color: #1890ff !important;
            border-color: #1890ff !important;
            color: #fff !important;
          }
        }
      }

      p {
        label {
          font-size: 12px;
          color: #999;
        }
      }

      .dollar-price {
        position: absolute;
        left: 280px;
        bottom: 40px;
        height: 32px;
        line-height: 32px;
        display: flex;
        align-items: center;
      }
    }

    .num-price-step {
      ::v-deep {
        .el-form-item__label {
          font-size: 16px;
          margin-right: 20px;
        }
      }
    }
  }

  ::v-deep {
    .inputClass {
      border-radius: 2.5px;
      color: #606266;
    }

    .inputClass:focus {
      border-color: #1890ff !important;
    }

    .el-table {
      border-bottom: 1px solid #ebeef5;

      .el-table__body td {
        height: 86px;

        .el-form-item {
          margin: 18px 0;
        }
      }
    }
  }

  .operate-icon {
    cursor: pointer;
    font-size: 16px;
    line-height: 32px;
  }
}

.calcu-p {
  font-size: 12px;
  color: #999;
  line-height: 32px;
  transform: translateX(-10px);
}

::v-deep.not-price {
  position: relative;

  span {
    position: absolute;
    left: -100px;
    top: 30px;
    font-size: 14px;
    color: #606266;
  }

  &.mar160 {
    span {
      left: -160px;
    }
  }
}

::v-deep.not-price2 {
  position: relative;

  span {
    position: absolute;
    left: -100px;
    top: 30px;
    font-size: 14px;
    color: #606266;
  }
}
::v-deep.not-price2-en {
  position: relative;

  span {
    position: absolute;
    left: -136px;
    top: 55px;
    font-size: 14px;
    color: #606266;
  }
}

.specifications-box {
  .el-form-item {
    margin-right: 30px;

    .el-input__inner {
      width: 180px !important;
      height: 32px !important;
    }
  }

  .symbol {
    display: inline-block;
    margin-right: 15px !important;
    margin-left: -15px;
    margin-top: 6px;

    &.mat32 {
      margin-top: 5px;
    }
  }

  .mat32 {
    margin-top: 32px;
  }
}
