@import "~@/erp/styles/variables/variables.scss";















































































































































































































































































































































































.mail-setting {
  padding: 20px;
}
.leave {
  color: #f56c6c;
  white-space: nowrap;
}
