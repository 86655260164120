@import "~@/erp/styles/variables/variables.scss";



































































































































































































































































































































































.el-radio-group {
  display: flex;
  flex-direction: column;
  margin-left: 30px;
}
.bottom {
  text-align: center;
  position: fixed;
  bottom: 20px;
  left: 50%;
}
