@import "~@/erp/styles/variables/variables.scss";




















































































































.pro-container {
  padding: 20px;

  .table-section {
    background: #fff;

    border-radius: 4px;

    .table-title {
      font-size: 16px;
      font-weight: 500;
      color: #333;
      margin: 15px 0;
      position: relative;
      font-size: 13px;
      color: rgba(0, 0, 0, 0.7);
    }
  }

  .mt40 {
    margin-top: 40px;
  }
}
