@import "~@/erp/styles/variables/variables.scss";












































































































































.infinite-scroll {
    height: 100%;
    overflow-y: auto;
  }
  .collection-notice-item {
    font-size: 14px;
    .mails-item--top {
      margin-bottom: 6px;
      color: #000;
      .mail-code {
        font-family: Semibold;
      }
      .sender {
        font-family: Regular;
      }
      .topic {
        font-family: Semibold;
      }
    }
    .mails-item--bottom {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      p {
        color: #5e5e5e;
        font-family: Regular;
        .label {
          margin-right: 5px;
        }
      }
    }
  }
