@import "~@/erp/styles/variables/variables.scss";





















































































































































































































































































































































.upload_file.erp-upload {
  margin-top: 0;
}
.custom-dialog {
  .el-form-item {
    margin-bottom: 18px;
  }
  p {
    margin: 0 0 10px;
  }
}
::v-deep {
  .el-upload--picture-card {
    width: 100px;
    height: 100px;
  }
  .el-icon-plus {
    position: relative;
    top: -20px;
  }
  .el-upload-list__item {
    width: 100px;
    height: 100px;
  }
  .el-upload-list__item-thumbnail {
    width: 100px;
    height: 100px;
  }
  .el-upload-list__item-actions {
    width: 100px;
    height: 100px;
  }
}
