@import "~@/erp/styles/variables/variables.scss";

























































































































































































































































.btn-span {
  display: inline-block;
  width: 100%;
  height: 100%;
  line-height: 26px;
}

.main-table {
  >.el-row {
    overflow-y: auto;
  }
}

::v-deep {
  .el-form--inline .el-form-item {
    margin-bottom: 0;
  }

  .el-table__row:not(.el_table__row--level-1) {
    >td:first-child {
      .cell {
        width: 200px;
        text-align: left;
        margin: 0 auto;
        white-space: nowrap;
        // .el-table__indent {
        //   padding-left: px !important;
        // }
      }
    }
  }

  .el-table__row.el-table__row--level-1 {
    .el-table__indent {
      padding-left: 32px !important;
    }
  }

  .el-table__row.el-table__row--level-2 {
    .el-table__indent {
      padding-left: 48px !important;
    }
  }

  .el-dropdown-menu__item {
    width: 80px;
    text-align: center;
  }

  .el-dropdown .el-button-group {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .el-icon-arrow-down {
    font-size: 14px;
  }

  .el-table__expand-icon {
    i {
      font-size: 16px;
      font-family: 'PingFang Bold';
    }
  }

  .el-dropdown-menu__item {
    line-height: 30px !important;

    a {
      width: 100%;
    }
  }

  .el-dropdown {
    .el-button-group {
      .el-button:first-child {
        width: 40px;
        height: 30px;
        padding: 2px;
      }
    }
  }

  .pagination-container {
    padding: 18.5px 10px 9.5px;
  }

  .el-pagination {
    margin-top: 0 !important;
  }
}
