<!-- 财务中心-付款管理 -->
<template>
  <div class="table-container">
    <div class="payment">
      <!-- header -->
      <el-row
        class="page-header"
        type="flex"
        align="middle"
        justify="space-between"
        style="padding-bottom: 20px; border-bottom: 1px solid #d8d8d8"
      >
        <el-col :span="20">
          <!-- 默认展示 -->
          <el-row type="flex" align="middle">
            <p v-if="selectRows.length">
              已选择
              <strong class="f_s_18 f_w_7">{{ selectRows.length }}</strong>
              个 &emsp; 合计
              <strong
                class="f_s_18 f_w_7"
                :style="totalAmount < 0 ? 'color:red' : ''"
              >
                {{ totalAmount | formatPrice }}
              </strong>
              元
            </p>
            <el-input
              type="text"
              placeholder="请输入付款单号/订单编号"
              clearable
              v-model.trim="requireForm.number"
              class="width-200 ml10"
            ></el-input>
            <el-button class="ml10 pub-h-36" @click="searchData">
              搜索
            </el-button>
            <template v-if="isPaymentDisabled">
              <el-button
                class="ml10 pub-h-36"
                plain
                v-allowed="['Affirm:Payment']"
                @click="affirmPayment()"
              >
                确认付款
              </el-button>
            </template>
            <el-button @click="highSearch" class="pub-h-36">高级筛选</el-button>
          </el-row>
        </el-col>
      </el-row>
      <el-row>
        <p class="divider"></p>
        <div class="custom-box">
          <el-radio-group
            v-model="type"
            @change="paymentStatusChange"
            class="mb5"
          >
            <el-radio :label="1">待处理</el-radio>
            <el-radio :label="6">银行处理中</el-radio>
            <el-radio :label="7">银行退票</el-radio>
            <el-radio :label="4">审核中</el-radio>
            <el-radio :label="3">已驳回</el-radio>
            <el-radio :label="2">已完成</el-radio>
            <el-radio :label="5">已撤销</el-radio>
            <el-radio :label="0">全部</el-radio>
          </el-radio-group>
          <el-popover
            ref="showOption"
            popper-class="custom-table-checkbox"
            trigger="hover"
          >
            <erp-draggable v-bind="dragOptions" :list="columns">
              <el-checkbox
                v-for="(item, index) in columns"
                :key="item + index"
                v-model="item.isShow"
                :disabled="item.checkable === false"
                :label="item.label"
              >
                {{ item.label }}
              </el-checkbox>
            </erp-draggable>
            <!-- 保存自定义显示列 -->
            <div class="save-btn">
              <el-button
                type="primary"
                class="ml10"
                size="mini"
                plain
                @click="operateSave"
              >
                保存
              </el-button>
            </div>
            <template #reference>
              <el-tooltip
                effect="dark"
                content="列表可拖动排序"
                placement="top-start"
              >
                <el-button plain type="primary" class="pub-h-36">
                  自定义显示
                </el-button>
              </el-tooltip>
            </template>
          </el-popover>
        </div>
      </el-row>

      <!-- table -->
      <ErpTable
        ref="BaseTable"
        :page-number.sync="pageNo"
        :page-size.sync="pageLe"
        :table-data="baseTableData"
        :total="total"
        :extraHeight="55"
        :key="customTableKey"
        @query="fetchData"
        class="mt10"
        @selection-change="setSelectRows"
        :default-sort="defaultSort"
        @sort-change="sortChange"
      >
        <el-table-column
          align="center"
          type="selection"
          width="55"
          :selectable="selectAble"
          v-show="type !== 2"
        ></el-table-column>
        <template v-for="(item, index) in filledColumns">
          <el-table-column
            :key="index"
            :prop="item.prop"
            :label="item.label"
            align="center"
            :width="item.width"
            v-if="item.isShow"
            :sortable="item.sortable ? 'custom' : false"
            show-overflow-tooltip
          >
            <template #default="{ row }">
              <!-- 付款单号 -->
              <div v-if="item.prop == 'number'">
                <span
                  class="blue-text"
                  @click="receiptNoClick(row.payOrderId, row.payType)"
                >
                  {{ row.number }}
                </span>

                <!--  添加警示原因 -->
                <erp-mouseover-tip v-if="row.state == 1 && row.errorMsg">
                  <template name="content">
                    <div>{{ row.errorMsg || '--' }}</div>
                  </template>
                </erp-mouseover-tip>
              </div>

              <div v-else-if="item.prop == 'applicationPayOrderNumber'">
                <span>
                  {{
                    row.applicationPayOrderNumber
                      ? row.applicationPayOrderNumber
                      : '--'
                  }}
                </span>
              </div>
              <!-- 付款类型 -->
              <div v-else-if="item.prop == 'payType'">
                <span v-if="row.entryType == 1">采购退款</span>
                <span v-else-if="row.payType == 1">采购付款</span>
                <span v-else-if="row.payType == 2">销售退款</span>
                <span v-else-if="row.payType == 3">网拍</span>
                <span v-else-if="row.payType == 4">信用额度还款</span>
              </div>
              <!-- 订单编号 -->
              <div v-else-if="item.prop == 'orderNumber'">
                <span
                  class="blue-text"
                  v-if="row.orderNumber && row.orderNumber.length == 1"
                  @click="orderDetailClick(row.orderNumber[0], row.payType)"
                >
                  {{ row.orderNumber[0] }}
                </span>
                <el-popover
                  placement="right"
                  trigger="hover"
                  v-else-if="row.orderNumber && row.orderNumber.length > 1"
                  popper-class="orderNumberPopover"
                >
                  <p
                    v-for="(item, index) in row.orderNumber"
                    :key="index"
                    class="blue-text"
                    @click="orderDetailClick(item, row.payType)"
                  >
                    {{ item }}
                  </p>
                  <span slot="reference">
                    <span
                      class="blue-text"
                      @click="orderDetailClick(row.orderNumber[0], row.payType)"
                    >
                      {{ row.orderNumber[0] }}
                    </span>
                    <span class="dot-span">
                      {{ row.orderNumber.length }}
                    </span>
                  </span>
                </el-popover>
              </div>
              <div v-else-if="item.prop == 'supplierName'">
                <el-row
                  type="flex"
                  align="middle"
                  justify="center"
                  class="supplierName-row"
                >
                  <div class="supplierName-span">
                    <p>
                      <span>{{ row.supplierName || '--' }}</span>
                    </p>
                  </div>
                  <p style="margin: 0 5px">
                    <el-tag
                      v-if="row.isAgreement == 1"
                      type="danger"
                      size="mini"
                    >
                      协议
                    </el-tag>
                  </p>
                </el-row>
              </div>
              <!-- 收款账户信息 -->
              <div v-else-if="item.prop == 'bankName'" class="courseTitle">
                <el-row v-if="row.payType === 2">
                  <span>{{ row.bankAccount }}</span>
                </el-row>
                <span v-if="row.payType === 1">
                  {{ row.accountName }} / {{ row.bankName }} /
                  {{ row.bankAccount }} / {{ row.dutyAccount }}
                </span>
              </div>
              <!-- 付款金额 -->
              <div
                v-else-if="item.prop == 'amountApplication'"
                :style="row.entryType == 1 ? 'color:red' : ''"
              >
                <span v-if="row.currency == '美元'">
                  $
                  {{ utils.numberFormat(row.amountApplication, 2) }}
                </span>
                <span v-else>
                  {{ utils.numberFormat(row.amountApplication, 2) }}
                </span>
              </div>
              <!-- 公账付款 -->
              <div v-else-if="item.prop == 'publicPayment'">
                <span>{{ row.publicPayment == 1 ? '是' : '否' }}</span>
              </div>
              <!-- 付款账号 -->
              <div v-else-if="item.prop == 'payee'">
                <span v-if="!row.payee">--</span>
                <span v-else>
                  {{ row.payee }}/{{ row.bankDeposit }}/{{ row.paymentAccount }}
                </span>
              </div>

              <!-- 科目 -->
              <div v-else-if="item.prop == 'courseTitle'" class="courseTitle">
                <span v-if="row.courseTitle && row.courseTitle != ''">
                  {{ row.courseTitle }}
                </span>
                <el-link
                  v-else
                  type="primary"
                  @click="subjectClick(row.payOrderId)"
                >
                  未设置
                </el-link>
              </div>
              <!-- 状态 -->
              <div v-else-if="item.prop == 'state'">
                <el-tag type="primary" plain v-if="row.state == 1" size="small">
                  待处理
                </el-tag>

                <el-tag
                  type="danger"
                  plain
                  v-if="row.state == 6"
                  size="small"
                  style="cursor: pointer"
                  @click="statusTypeClick(row)"
                >
                  审核驳回
                </el-tag>
                <el-tooltip
                  placement="top"
                  effect="light"
                  v-if="row.state == '5'"
                >
                  <div slot="content">
                    <span>
                      {{ '撤销人：' + (row.updatorName || '--') }}
                    </span>
                    <br />
                    <span>
                      {{ '撤销原因：' + (row.rejectReason || '--') }}
                    </span>

                    <br />
                    <span>
                      {{ '撤销时间：' + (row.updateTime || '--') }}
                    </span>
                  </div>
                  <el-tag
                    type="danger"
                    plain
                    v-if="row.state == 5"
                    size="small"
                  >
                    已撤销
                  </el-tag>
                </el-tooltip>

                <el-tag
                  type="primary"
                  plain
                  v-if="row.state == 4"
                  size="small"
                  style="cursor: pointer"
                  @click="statusTypeClick(row)"
                >
                  审核中
                </el-tag>
                <el-tooltip
                  placement="top"
                  effect="light"
                  v-if="row.state == '3'"
                >
                  <div slot="content">
                    <span>
                      {{ '驳回人：' + (row.updatorName || '--') }}
                    </span>
                    <br />
                    <span>
                      {{ '驳回原因：' + (row.rejectReason || '--') }}
                    </span>

                    <br />
                    <span>
                      {{ '驳回时间：' + (row.updateTime || '--') }}
                    </span>
                  </div>
                  <el-tag
                    type="danger"
                    plain
                    v-if="row.state == 3"
                    size="small"
                  >
                    付款驳回
                  </el-tag>
                </el-tooltip>
                <el-tag type="success" plain v-if="row.state == 2" size="small">
                  已完成
                </el-tag>
                <el-tag type="primary" plain v-if="row.state == 7" size="small">
                  银行处理中
                </el-tag>
              </div>
              <!-- 支付状态 -->
              <div v-else-if="item.prop === 'payStatus'">
                {{ getPaymentStatus(row) }}
              </div>

              <!-- 其他 -->
              <span v-else>{{ row[item.prop] || '--' }}</span>
            </template>
          </el-table-column>
        </template>
        <el-table-column
          label="操作"
          width="180"
          align="center"
          fixed="right"
          :resizable="false"
        >
          <template slot-scope="scope">
            <!--   收款方为对公账户和个人银行卡才显示此入口 1  对公账户 2：个人银行卡-->
            <el-tooltip
              content="CBS支付"
              placement="top"
              v-if="
                (scope.row.accountType == 1 || scope.row.accountType == 2) &&
                scope.row.state == 1 &&
                scope.row.entryType != 1 &&
                scope.row.currency == '人民币'
              "
            >
              <i
                class="iconfont icon-zhaoshangyinhang red"
                v-allowed="['PAYMENT:CBS']"
                @click="affirmPayment(scope.row.payOrderId, true)"
              ></i>
            </el-tooltip>
            <el-tooltip
              content="确认付款"
              placement="top"
              v-if="
                scope.row.state == 1 &&
                scope.row.operatePayOrder === '1' &&
                requireForm.type != 2
              "
            >
              <el-button
                type="primary"
                plain
                icon="el-icon-check"
                circle
                size="mini"
                @click="affirmPayment(scope.row.payOrderId)"
                v-allowed="['Affirm:Payment']"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              content="驳回"
              placement="top"
              v-if="
                scope.row.state == 1 &&
                scope.row.operatePayOrder === '1' &&
                requireForm.type != 2
              "
            >
              <el-button
                type="danger"
                plain
                icon="el-icon-close"
                circle
                size="mini"
                v-allowed="['Payment:Reject']"
                @click="reject(scope.row.payOrderId)"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              content="审核撤销"
              placement="top"
              v-if="isLoginUser(scope.row) && scope.row.state == 4"
            >
              <el-button
                type="danger"
                plain
                icon="iconfont icon-quxiao"
                circle
                size="mini"
                @click="revert(scope.row)"
              ></el-button>
            </el-tooltip>
            <!-- v1.8.3重新提交  -->
            <el-tooltip
              content="重新提交"
              placement="top"
              v-if="
                isLoginUser(scope.row) &&
                [3, 6, '3', '6', 5, '5'].includes(scope.row.state)
              "
            >
              <el-button
                type="primary"
                plain
                icon="iconfont icon-zhongxintijiao"
                circle
                size="mini"
                @click="reAdd(scope.row.payOrderId)"
              ></el-button>
            </el-tooltip>
            <!-- 银行退票状态显示手动完结按钮 -->
            <el-tooltip
              class="item"
              content="手动完结"
              placement="top"
              v-if="scope.row.payStatus == 4"
            >
              <el-button
                type="primary"
                plain
                size="mini"
                icon="el-icon-video-pause"
                circle
                @click="endClick(scope.row)"
                v-allowed="['Payment:Finished']"
              ></el-button>
            </el-tooltip>
            <!-- 电子回单 -->
            <el-tooltip
              content="电子回单"
              placement="top"
              v-if="scope.row.receiptFile"
            >
              <router-link
                :to="`/preview-file/index?name=${
                  scope.row.number + '.pdf'
                }&url=${scope.row.receiptFile}`"
                target="_blank"
              >
                <el-button
                  type="primary"
                  plain
                  size="mini"
                  icon="el-icon-tickets"
                  circle
                ></el-button>
              </router-link>
            </el-tooltip>
          </template>
        </el-table-column>
      </ErpTable>
    </div>
    <!-- 付款单详情弹窗1 -->
    <PaymentDetailModel ref="PaymentDetailModel" />
    <!-- 付款单详情弹窗2,销售退款 -->
    <PaymentDetailModel2 ref="PaymentDetailModel2" />

    <!-- 订单详情-采购订单 -->
    <OrderDetailModel ref="OrderDetail" @fet-detail="costReceiptNoClick" />
    <!-- 订单详情-销售订单 -->
    <OrderDetailModel2 ref="OrderDetail2" />

    <!-- 确认付款弹窗 -->
    <AffirmPayment ref="AffirmPayment" @getList="fetchData" />
    <!-- 驳回弹窗 -->
    <PaymentReject ref="PaymentReject" @getList="fetchData" />
    <!-- 设置科目 -->
    <PaymentSubject ref="subjectClick" @getList="fetchData" />
    <!-- 高级筛选Drawer -->
    <el-drawer
      title="高级筛选"
      :visible.sync="drawer"
      :before-close="highSearchClose"
    >
      <paymentSearch
        @cancelClick="highSearchClose"
        @searchClick="searchClick"
      />
    </el-drawer>
    <el-drawer
      title="审核进度"
      :visible.sync="statusDrawer"
      :before-="statusClose"
    >
      <Audit
        ref="Audit"
        :auditObjectId="auditObjectId"
        :auditType="7"
        @cancelClick="statusClose"
      />
    </el-drawer>
    <!-- 1.8.3撤销弹窗 -->
    <CostCancel ref="CostCancel" @getList="delayFetchData" :auditType="7" />
    <!--  1.8.3 重新提交 -->
    <OrderPayment
      :isResubmit="true"
      title="付款\退款申请"
      ref="OrderPayment"
      @refresh="delayFetchData"
    ></OrderPayment>
    <!-- 费用单详情弹窗 -->
    <CostPaymentDetailModel ref="CostPaymentDetailModel" />
  </div>
</template>

<script>
  import { listPage } from '@/api/finance/finance-payment'
  import BaseTable from '@/components/baseTable'
  import CostPaymentDetailModel from '@/views/finance-center/cost-manage/components/payment-detail-model' // 费用管理-费用单详情弹框
  import PaymentDetailModel from './components/payment-detail-model'
  import PaymentDetailModel2 from './components/payment-detail-model2'
  import OrderDetailModel from './components/order-detail-model'
  import OrderDetailModel2 from './components/order-detail-model2'
  import PaymentSubject from './components/payment-subject'
  import paymentSearch from './components/payment-search'
  import AffirmPayment from './components/affirm-payment'
  import PaymentReject from './components/payment-reject'
  import Audit from '@/components/Audit/ApprovalsDrawer'
  import CostCancel from '@/views/finance-center/cost-manage/components/cost-cancel'
  import { CostManageInteractor } from '@/core/interactors/finance/cost-manage'
  import OrderPayment from '@/views/purchase/purchaseOrder/components/orderPayment.vue'
  import ErpDraggable from 'vuedraggable'
  import { mapState, mapActions, mapGetters } from 'vuex'
  import { paymentStatusList } from './helper/data'

  import CustomCache from '@/utils/custom-cache'

  export default {
    name: 'paymentList',
    components: {
      ErpDraggable,
      BaseTable,
      CostPaymentDetailModel,
      PaymentDetailModel,
      PaymentDetailModel2,
      OrderDetailModel,
      OrderDetailModel2,
      paymentSearch,
      AffirmPayment,
      PaymentReject,
      PaymentSubject,
      Audit,
      CostCancel,
      OrderPayment,
    },
    data() {
      return {
        customTableKey: '',
        drawer: false,
        auditObjectId: '', //审核id
        statusDrawer: false, //审核进度,
        requireForm: {
          number: '', // 单号
          sortName: '',
          sortType: '',
        }, //请求参数
        type: 1, // tab页序号（全部传0，其余从1开始）
        total: 0, //分页总数
        pageNo: 1, //当前页
        pageLe: 10, //一页条数
        // 表格数据
        baseTableData: [],
        selectRows: [], //多选
        isPaymentDisabled: true, //是否可以批量付款
        columnsOldData: [], //存储接口返回的列
        // 列表列
        columns: [
          {
            label: '付款单号',
            width: '160',
            prop: 'number',
            isShow: true,
            checked: true,
            isSort: false,
          },
          {
            label: '备注',
            width: '160',
            prop: 'remark',
            isShow: true,
            checked: true,
            isSort: false,
          },
          {
            label: '付款类型',
            width: '120',
            prop: 'payType',
            isShow: true,
            checked: true,
            isSort: false,
          },
          {
            label: '订单编号',
            width: '160',
            prop: 'orderNumber',
            isShow: true,
            checked: true,
            isSort: false,
          },
          {
            label: '买方',
            width: '170',
            prop: 'purchaser',
            isShow: true,
            checked: true,
            isSort: false,
          },
          {
            label: '收款方',
            width: '180',
            prop: 'supplierName',
            isShow: true,
            checked: true,
            isSort: false,
          },
          {
            label: '收款账户信息',
            width: '350',
            prop: 'bankName',
            isShow: true,
            checked: true,
            isSort: false,
          },
          {
            label: '币种',
            width: '120',
            prop: 'currency',
            isShow: true,
            checked: true,
            isSort: false,
          },
          {
            label: '付款金额',
            width: '120',
            prop: 'amountApplication',
            isShow: true,
            checked: true,
            isSort: false,
          },
          {
            label: '所属地区',
            width: '120',
            prop: 'buyerArea',
            isShow: true,
            checked: true,
            isSort: false,
          },
          {
            label: '公账付款',
            width: '100',
            prop: 'publicPayment',
            isShow: false,
            checked: true,
            isSort: false,
          },
          {
            label: '付款账号',
            width: '300',
            prop: 'paymentAccount',
            isShow: true,
            checked: true,
            isSort: false,
          },
          {
            label: '支付状态',
            width: '300',
            prop: 'payStatus',
            isShow: true,
            checked: true,
            isSort: false,
          },
          {
            label: '实际付款时间',
            width: '160',
            prop: 'paymentDate',
            isShow: false,
            checked: true,
            isSort: false,
          },
          {
            label: '科目',
            width: '120',
            prop: 'courseTitle',
            isShow: false,
            checked: true,
            isSort: false,
          },
          {
            label: '状态',
            width: '100',
            prop: 'state',
            isShow: true,
            checked: true,
            isSort: false,
          },
          {
            label: '失败原因',
            width: '120',
            prop: 'errorMsg',
            isShow: true,
            checked: true,
            isSort: false,
          },
          {
            label: '创建人',
            width: '140',
            prop: 'creator',
            isShow: true,
            checked: true,
            isSort: false,
          },
          {
            label: '创建时间',
            width: '180',
            prop: 'createTime',
            isShow: true,
            checked: true,
            isSort: false,
            sortable: true,
          },
          {
            label: '审核通过时间',
            width: '180',
            prop: 'auditTime',
            isShow: true,
            checked: true,
            isSort: false,
            sortable: true,
          },
        ],
        defaultSort: {}, // 默认排序
      }
    },
    computed: {
      ...mapState({
        currencyList: (state) => state.warehouseCenter.currencyList,
      }),
      filledColumns() {
        return this.columns.filter((item) => item.isShow === true)
      },
      dragOptions() {
        return {
          animation: 600,
          group: 'description',
        }
      },
      ...mapGetters({
        userInfo: 'user/userInfo',
      }),
      totalAmount() {
        return this.selectRows.reduce((total, item) => {
          let currency = this.currencyList.find((obj) => {
            return obj.financeName == item.currency
          })
          if (currency) {
            return total + item.amountApplication * currency.exchangeRate
          } else {
            return total
          }
        }, 0)
      },
    },
    created() {
      this.getCurrency()
      // this.columnTable(1)
      this.getColumsData()
      // this.fetchData()
    },
    methods: {
      ...mapActions({
        getCurrency: 'warehouseCenter/getCurrency',
      }),
      //  1.8.3 重新提交
      async reAdd(payOrderId) {
        let auditParams = {
          auditType: 7,
          status: 0,
          tenantId: this.userInfo.tenantId,
        }
        this.pubApi.getAuditTemplates(auditParams).then((response) => {
          if (!response.data.length) {
            self.$baseMessage(
              '请联系管理员配置审核模板',
              'warning',
              false,
              'erp-hey-message-warning'
            )
            return false
          } else {
            this.$refs.OrderPayment.initLoading(payOrderId)
          }
        })
      },

      // 1.8.3审核进度关闭
      statusClose() {
        this.statusDrawer = false
      },
      // 1.8.3审核进度查看
      statusTypeClick(row) {
        this.auditObjectId = row.payOrderId
        this.statusDrawer = true
      },
      // 重新提交和撤销审核判断
      isLoginUser({ creatorId }) {
        return this.userInfo.userId === creatorId
      },
      // 和后端约定延时更新
      delayFetchData() {
        setTimeout(() => {
          this.fetchData()
        }, 1300)
      },
      // 获取列表数据
      async fetchData(isTablekeyChange = true) {
        this.isRequesting = true

        let response = await listPage({
          ...this.requireForm,
          type: this.type,
          pageNo: this.pageNo,
          pageLe: this.pageLe,
        })

        if (isTablekeyChange) {
          setTimeout(() => {
            this.customTableKey = Math.random()
          }, 300)
        }

        this.baseTableData = response?.data?.data || []
        this.baseTableData.forEach((item) => {
          if (item.orderNumber) {
            this.$set(item, 'orderNumber', item.orderNumber.split(','))
          }

          if (item.entryType == 1) {
            this.$set(
              item,
              'amountApplication',
              0 - Number(item.amountApplication)
            )
          }
        })

        this.total = response?.data?.total || 0
        this.isRequesting = false

        // 从PaymentDetail组件跳转过来，会通过query传过来paymentOrderId
        let paymentDetailBool = localStorage.getItem('paymentDetailBool')
        paymentDetailBool = paymentDetailBool
          ? JSON.parse(paymentDetailBool)
          : false
        console.log('paymentDetailBool', paymentDetailBool)
        if (this.$route.query.paymentOrderId && paymentDetailBool) {
          this.$nextTick(() => {
            this.receiptNoClick(this.$route.query.paymentOrderId, 1)
            localStorage.setItem('paymentDetailBool', false)
          })
        }
      },
      // 判断是否可选
      selectAble(row, index) {
        if (row.state != 1) {
          return false
        }
        return true
      },
      // 多选
      setSelectRows(val) {
        this.selectRows = val || []
        this.isPaymentDisabled = false
        //相同付款类型
        let isCommonType = this.selectRows.every((item) => {
          return item.payType == this.selectRows[0].payType
        })

        // entryType相同
        let isSameEntryType = this.selectRows.every((item) => {
          return item.entryType == this.selectRows[0].entryType
        })

        //相同采购开发
        let isCommonBuyer = this.selectRows.every((item) => {
          return item.buyerId == this.selectRows[0].buyerId
        })
        //相同供应商
        let isCommonSupplier = this.selectRows.every((item) => {
          return item.supplierId == this.selectRows[0].supplierId
        })
        // 相同的买方
        let isCommonPurchaser = this.selectRows.every((it) => {
          return it.purchaser == this.selectRows[0].purchaser
        })
        if (
          this.selectRows.length &&
          this.selectRows[0].payType == 1 &&
          this.selectRows.every(
            (item) =>
              item.operatePayOrder === '1' &&
              this.selectRows[0].currency == item.currency
          ) &&
          isCommonType &&
          isCommonSupplier &&
          isCommonPurchaser &&
          isSameEntryType
        ) {
          // 申请的单据中为同一个供应商（采购付款）
          this.isPaymentDisabled = true
        }
        if (
          this.selectRows.length &&
          this.selectRows[0].payType == 2 &&
          this.selectRows.every(
            (item) =>
              item.operatePayOrder === '1' &&
              this.selectRows[0].currency == item.currency
          ) &&
          isCommonType &&
          isCommonBuyer &&
          isCommonPurchaser &&
          isSameEntryType
        ) {
          // 申请的单据中为同一个客户代表（销售退款）
          this.isPaymentDisabled = true
        }
      },
      // 状态的改变
      paymentStatusChange(val) {
        this.pageNo = 1
        this.type = val
        this.isRequesting = true
        this.columnTable(val)
        this.type = val
        this.fetchData()
      },
      // 隐藏列
      columnTable(paymentStatus) {
        // 过滤掉不显示的列
        const statusHideColumns = {
          0: [
            'publicPayment',
            'paymentAccount',
            'payStatus',
            'paymentDate',
            'courseTitle',
            'errorMsg',
            'auditTime',
          ],
          1: [
            'publicPayment',
            'paymentAccount',
            'payStatus',
            'paymentDate',
            'courseTitle',
            'errorMsg',
          ],
          2: ['amountApplication', 'payStatus', 'errorMsg', 'auditTime'],
          3: [
            'amountApplication',
            'publicPayment',
            'paymentAccount',
            'payStatus',
            'paymentDate',
            'courseTitle',
            'errorMsg',
            'auditTime',
          ],
          4: [
            'amountApplication',
            'publicPayment',
            'paymentAccount',
            'payStatus',
            'paymentDate',
            'courseTitle',
            'errorMsg',
            'auditTime',
          ],
          5: [
            'amountApplication',
            'publicPayment',
            'paymentAccount',
            'payStatus',
            'paymentDate',
            'courseTitle',
            'errorMsg',
            'auditTime',
          ],
          6: [
            'publicPayment',
            'paymentDate',
            'courseTitle',
            'state',
            'errorMsg',
            'auditTime',
          ],
          7: [
            'publicPayment',
            'payStatus',
            'paymentDate',
            'courseTitle',
            'state',
            'auditTime',
          ],
        }
        this.customTableKey = Math.random()
        this.columns = JSON.parse(JSON.stringify(this.columnsOldData))
        this.columns.forEach((item, index) => {
          if (statusHideColumns[paymentStatus].includes(item.prop)) {
            this.$set(this.columns[index], 'isShow', false)
          }
        })
      },
      // 设置科目
      subjectClick(row) {
        this.$refs.subjectClick.amendRow = row
        this.$refs.subjectClick.showAdd()
      },
      //点击打开费用的详情弹框
      costReceiptNoClick(row) {
        this.$refs.CostPaymentDetailModel.amendRow = row
        this.$refs['CostPaymentDetailModel'].showReceiptDetail()
      },
      //点击付款单号(费用单号)
      receiptNoClick(payOrderId, payType) {
        if (payType === 1) {
          this.$refs['PaymentDetailModel'].amendRow = payOrderId
          this.$refs['PaymentDetailModel'].showReceiptDetail()
        } else {
          this.$refs['PaymentDetailModel2'].amendRow = payOrderId
          this.$refs['PaymentDetailModel2'].showReceiptDetail()
        }
      },
      //点击订单编号
      orderDetailClick(row, payType) {
        if (payType === 1) {
          //采购订单
          this.$refs['OrderDetail'].amendRow = row
          this.$refs['OrderDetail'].payType = payType
          this.$refs['OrderDetail'].showOrderDetail()
        } else {
          //销售退款及其他
          this.$refs['OrderDetail2'].amendRow = row
          this.$refs['OrderDetail2'].payType = payType
          this.$refs['OrderDetail2'].showOrderDetail()
        }
      },
      // 确认付款按钮
      affirmPayment(id, isCBS) {
        if (id) {
          this.$refs.AffirmPayment.showReceiptDetail(id, 'one', isCBS)
        } else {
          let ids = this.selectRows
            .map((item) => {
              return item.payOrderId
            })
            .join(',')
          if (!ids) {
            this.$message.info('请最少选择一条')
            return
          }
          this.$refs.AffirmPayment.showReceiptDetail(ids, 'more')
        }
      },
      // 驳回按钮
      reject(row) {
        this.$refs.PaymentReject.amendRow = row
        this.$refs.PaymentReject.showReceiptDetail()
      },

      // 撤销审核
      revert(row) {
        const { payOrderId } = row
        //  1.8.3调用付款撤销审核接口
        let dt = {
          orderId: payOrderId,
          auditType: 7,
          tenantId: this.userInfo.tenantId,
        }
        CostManageInteractor.getAudit(dt).then((res) => {
          this.$refs.CostCancel.showCancel(res.data.auditObjectId)
        })
      },

      // 高级筛选
      highSearch() {
        this.drawer = true
      },

      // 高级筛选关闭
      highSearchClose() {
        this.drawer = false
      },
      // 搜索
      searchData() {
        this.pageNo = 1
        this.fetchData()
      },

      // 高级筛选触发
      searchClick(val) {
        this.pageNo = 1
        this.requireForm = val
        this.fetchData()
        this.highSearchClose()
      },

      getPaymentStatus(item) {
        return (
          paymentStatusList.find((s) => s.code == item.payStatus)?.name || '--'
        )
      },

      // 手动完结
      endClick({ payOrderId }) {
        this.$confirm(`确认已付款并手动完结？`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            CostManageInteractor.handleBankReturn(payOrderId).then((res) => {
              if (res && res.code === '000000') {
                this.$message.success('操作成功')
                this.searchData()
              }
            })
          })
          .catch(() => {})
      },

      // 获取自定义保存
      getColumsData() {
        CustomCache.getColumns(66, this.userInfo.userId)
          .then((res) => {
            if (res && res.columns) {
              this.userCustomizeColumnId = res.userCustomizeColumnId
              this.columns = res.columns
              this.columnsOldData = JSON.parse(JSON.stringify(res.columns))
              // 处理排序搜索条件
              this.columns.forEach((item) => {
                if (item.isSort) {
                  if (item.prop === 'auditTime') {
                    this.requireForm.sortName = 'audit_time'
                  } else if (item.prop === 'createTime') {
                    this.requireForm.sortName = 'create_time'
                  }
                  this.requireForm.sortType = item.sortType
                  this.defaultSort = { prop: item.prop, order: item.sortType }
                  // 没有排序的情况下sortName传null
                  !item.sortType && (this.requireForm.sortName = null)
                }
              })
              this.columnTable(1)
              this.fetchData()
            }
          })
          .catch(() => {
            this.columnsOldData = JSON.parse(JSON.stringify(this.columns))
            this.columnTable(1)
            this.fetchData()
          })
      },

      // 保存自定义显示列
      operateSave(bool) {
        let customizeData = JSON.stringify(this.columns)
        let params = {
          userCustomizeColumnId: this.userCustomizeColumnId,
          menuType: 66,
          userId: this.userInfo.userId,
          customizeData,
        }
        CustomCache.setColumns(params).then((res) => {
          if (res.code === '000000' && bool) {
            this.userCustomizeColumnId = res.data.userCustomizeColumnId
            this.columnsOldData = JSON.parse(JSON.stringify(this.columns))
            return this.$baseMessage(
              '操作成功',
              'success',
              false,
              'erp-hey-message-success'
            )
          }
        })
      },

      // 排序
      sortChange(column) {
        if (column.prop === 'auditTime') {
          this.requireForm.sortName = 'audit_time'
        } else if (column.prop === 'createTime') {
          this.requireForm.sortName = 'create_time'
        } else {
          this.requireForm.sortName = ''
        }
        this.requireForm.sortType = column.order
        this.saveSort(column)
        this.pageNo = 1
        this.fetchData(false)
      },

      // 保存排序
      saveSort(column) {
        this.columns.forEach((item) => {
          if (item.prop === column.prop) {
            item.isSort = true
            item.sortType = column.order
          } else {
            item.isSort = false
            item.sortType = null
          }
        })
        this.operateSave()
      },
    },
  }
</script>
<style>
  .el-popover.orderNumberPopover {
    max-height: 400px;
    overflow-y: auto;
  }
</style>
<style scoped lang="scss">
  .main-height {
    height: calc(100% - 90px);
  }

  .table-height-box {
    height: calc(100% - 4px);
    overflow: hidden;
    overflow-y: auto;
    overflow-x: scroll;
  }

  //自定义显示
  .custom-table-checkbox {
    .el-checkbox {
      display: block !important;
      margin: 8px 0 8px 10px !important;
    }
  }

  .save-btn {
    border-top: 1px solid #ececec;
    padding-top: 10px;
    text-align: center;
  }

  .width-200 {
    width: 200px;
  }

  .dot-span {
    display: inline-block;
    // width: 15px;
    padding: 0 5px;
    height: 15px;
    line-height: 15px;
    font-size: 12px;
    background: #409eff;
    color: #fff;
    // border-radius: 50%;
    border-radius: 7px;
    cursor: pointer;
    margin-left: 5px;
  }

  .courseTitle {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .icon-zhaoshangyinhang {
    margin-right: 10px;
    font-size: 25px;
    cursor: pointer;
    position: relative;
    top: 4px;
  }

  .supplierName-row {
    .supplierName-p {
      flex: 1;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      text-align: center;
    }
  }

  ::v-deep {
    .payment {
      .el-table__body-wrapper {
        height: 630px !important;
      }

      .el-table {
        overflow: auto;
        height: 670px !important;
      }
    }
  }

  .supplierName-row {
    .supplierName-span {
      width: 90%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  .custom-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0 0;
  }
</style>
