@import "~@/erp/styles/variables/variables.scss";






























































































































































































































































.dialog-content {
  overflow: hidden;
  overflow-y: auto;
  padding: 0 20px;
  .el-form {
    width: 95%;
    margin: 0 auto;
  }
  .selected-users {
    margin-top: 10px;
    .user-tag {
      margin-right: 8px;
      margin-bottom: 8px;
      .status-dot {
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin-left: 5px;
        &.online {
          background-color: #67c23a;
        }
        &.offline {
          background-color: #909399;
        }
      }
    }
  }
}
::v-deep {
  .el-select {
    width: 100%;
  }
}
::v-deep {
  .el-switch__label.is-active {
    background-color: transparent !important;
  }
}
::v-deep {
  .el-dialog.bind-mail .el-dialog__body {
    padding: 0px !important;
  }
}
.add-btn-wrappper {
  display: flex;
  justify-content: flex-end;
}
.table-wrapper {
  height: 220px;
}
.el-dialog.bind-mail .el-dialog__body {
  padding: 0px !important;
}
.line {
  height: 1px;
  background-color: #ebeef5;
  margin: 20px 0;
  width: 100%;
  &.mt-10 {
    margin-top: 10px;
  }
  &.mb-10 {
    margin-bottom: 10px;
  }
}
