@import "~@/erp/styles/variables/variables.scss";































































































































































































































































































































































































































































































































































































































































































































































.table-container {
  padding: 20px !important;

  & .title {
    display: flex;
    align-items: center;
  }

  & .detail-fields {
    .el-row {
      & .label {
        color: #282c34;
        width: 100px;
        text-align: right;
        display: inline-block;
        margin-right: 15px;
      }

      & .content {
        color: #000016;
      }
    }
  }
}

.detail-content {
  font-size: 16px !important;
  margin-top: 10px;
  font-weight: normal;
}

.header {
  font-size: 18px;
  color: #606266;
  //font-family: 'PingFang Bold';
}

.fs14 {
  font-size: 14px;
  font-family: 'PingFang Bold';
  color: #282c34;
}

.mr15 {
  margin-right: 15px;
}

.mt20 {
  margin-top: 20px;
}

.mt10 {
  margin-top: 10px;
}

.pointer {
  cursor: pointer;
}

.mb20 {
  margin-bottom: 20px;
}

.loc-content {
  max-height: 300px;
  overflow: auto;
}

.left,
.right {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  display: inline-block;
  display: inline-block;
  border: 1px solid #909399;
  z-index: 1000;
  border-radius: 50%;
}

.el-icon-caret-right,
.el-icon-caret-left {
  font-size: 36px;
  color: rgb(40, 130, 255);
}

.right {
  right: 20px;
}

.left {
  left: 7px;
}

.inline-block {
  display: inline-block;
}
