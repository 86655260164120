@import "~@/erp/styles/variables/variables.scss";




















































































































































































































































































































































































































































.wewoo-remote-select .el-autocomplete-suggestion {
  width: auto !important;
  min-width: 100px;
  text-align: left;
}
.add-new-note{
  position: absolute !important;
  left: 50% !important;
  top: 0 !important;
  bottom: 0 !important;
  margin-left: -550px !important;
  margin-bottom: 0 !important;
  display: flex !important;
  flex-direction: column !important;
  .el-dialog__header{
    padding: 10px 0 20px 10px !important;
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
  }
  .el-dialog__body{
    flex: 1 !important;
    overflow-y: auto !important;
    padding: 0 20px !important;
    .query-form-heard{
      padding: 0 !important;
      .el-col{
        padding: 0 30px !important;
        &.search-btn{
          text-align: right !important;
          margin-bottom: 20px !important;
        }
      }
    }
  }
  .pl11{
    .el-form-item__label{
      padding-left: 11px !important;
    }
  }
  .total-amount{
      font-size: 16px;
      color: #000;
      margin-bottom: 20px;
   }
}
