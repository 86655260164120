@import "~@/erp/styles/variables/variables.scss";
























































































































































































































































































































































































































































































































































































































































































































.custom-dialog {
  .top {
    display: flex;
    justify-content: flex-start;
    height: 32px;
    line-height: 30px;
    margin-bottom: 20px;

    h4 {
      font-size: 14px;
      font-family: 'PingFang Bold';
      margin: 0 20px 0 0;
    }
  }

  p {
    margin: 10px 0;

    span {
      font-size: 16px;
      font-family: 'PingFang Bold';
    }
  }
}
