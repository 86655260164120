@import "~@/erp/styles/variables/variables.scss";







































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.el-message-box.add-tip {
  .el-message-box__message p {
    word-break: break-all;
    text-align: justify;
    max-height: 450px;
    overflow: auto;
  }
}
