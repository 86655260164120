@import "~@/erp/styles/variables/variables.scss";
















































































































































































































































































.expand-table {
  width: 1300px;
  margin-left: 55px;

  .product-dl {
    display: flex;
    align-items: center;
    padding: 5px 0;
    width: 100%;

    .split-wrap {
      position: relative;

      em {
        position: absolute;
        right: 0;
        bottom: 0;
        width: auto;
        text-align: center;
        cursor: pointer;
      }
    }

    dt {
      width: 70px;
      height: 70px;
      border-radius: 4px;
      box-shadow: 0 0 5px #ccc;
      overflow: hidden;
      margin-right: 10px;
      position: relative;
    }

    .main-img {
      display: block;
      width: 100%;
      height: 100%;
      background-color: #f8f8f8;
    }

    .mark {
      position: absolute;
      right: 0;
      top: 0;
      font-size: 12px;
      line-height: 12px;

      p {
        padding: 0 3px;
        color: #1890ff;
        background: #e8f4ff;
        border-radius: 4px;

        &.el-tag-other {
          background-color: #fff7e8;
          border-color: #ffefd1;
          color: #ffae18;
        }
      }
    }

    dd {
      min-width: 100px;
      flex: 1;
      line-height: 18px;
    }
  }

  .design-pic {
    position: relative;

    .el-badge {
      position: absolute;
      right: 0;
      top: 5px;
      z-index: 2;
    }
  }

  .pic {
    width: 70px;
    height: 70px;
    border-radius: 4px;
    box-shadow: 0 0 5px #ccc;
    background-color: #ccc;
  }

  .process {
    text-align: left;

    .process-item {
      padding-top: 5px;
      padding-bottom: 5px;
      display: flex;
      align-items: center;

      &+.process-item {
        border-top: 1px dashed #000;
      }
    }
  }

  .status-mark {
    display: inline-block;
    text-align: center;
    border-radius: 2px;
    height: 20px;
    line-height: 18px;
    font-size: 12px;
    margin-left: 10px;
    border-width: 1px;
    border-style: solid;
  }
}

::v-deep {
  .expand-table tr {
    height: auto;

    th.el-table__cell {
      height: 38px;
      padding: 4px 0;
    }
  }

  .expand-table .el-table-cell {
    padding: 4px 0;
  }

  .child-img {
    width: 70px;
    height: 70px;
    border-radius: 2px;
  }
}
