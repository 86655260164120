@import "~@/erp/styles/variables/variables.scss";
































































































































































































.upload-file-box {
  display: flex;
  align-items: center;
  span {
    width: auto !important;
  }
  .el-button {
    text-align: left !important;
  }
}
