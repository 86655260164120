@import "~@/erp/styles/variables/variables.scss";

































































































































































































































































































































































































.wewoo-remote-select /deep/ .el-autocomplete-suggestion {
  width: auto !important;
  min-width: 100px;
  text-align: left;
}
.ticket-table{
  ::v-deep.el-table {
    .cell{
      padding: 0 2px;
      .el-form-item{
        margin: 14px 0;
        .el-select{
          width: 100% !important;
        }
        .el-date-editor{
          width: 100% !important;
          &.el-input{
            .el-input__inner{
              padding: 0 4px 0 26px;
            }
          }
        }
        .el-input{
          .el-input__inner{
            text-align: center;
            padding: 0 4px;
          }
        }
      }
    }
  }
  .add-row{
    text-align: center;
    padding: 10px 0 30px;
    span{
      cursor: pointer;
      color: rgb(64, 158, 255);
    }
  }
}

