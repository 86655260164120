@import "~@/erp/styles/variables/variables.scss";





























































































































































































































































































































.list-search{
  ::v-deep .el-date-editor{
    width: 100% !important;
    padding: 3px;
  }
  .multiple-search-box{
    display: flex;
    .multiple-search-wrapper{
      flex: 1;
    }
  }
}
