@import "~@/erp/styles/variables/variables.scss";





































































































































































































































































































































































































.footer {
  .el-button + .el-button {
    margin-left: 30px;
  }
}
.ml15 {
  margin-left: 15px;
}
.img-wrap {
  position: relative;
  padding: 3px 0;
  width: 50px;
  margin: 0 auto;
  .el-image {
    width: 50px;
    height: 50px;
    object-fit: cover;
    display: block;
  }
  .el-badge {
    position: absolute;
    top: 3px;
    right: -10px;
    z-index: 2;
  }
}
::v-deep {
  .el-dialog__body {
    padding: 0 20px 20px;
  }
}
