@import "~@/erp/styles/variables/variables.scss";





















































































































































































































































































































































































































































































.bg-wrapper {
  padding: 0 20px;
  background-color: #f2f2f2;
}
.bg {
  height: 68px;
  display: flex;
  align-items: center;
  .el-form-item {
    margin-bottom: 0px !important;
  }
  margin-bottom: 25px;
}
.btn-group {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding-right: 20px;
}
::v-deep {
  .el-input.is-disabled .el-input__inner {
    background-color: #f2f2f2 !important;
    color: #000000 !important;
  }
}
::v-deep {
  .el-dialog__body {
    padding: 5px 20px !important;
  }
}
::v-deep {
  .el-table--border {
    border: 1px solid #ebeef5 !important;
  }
}
::v-deep {
  .el-table .el-form-item--small.el-form-item {
    margin: 5px 0 !important;
    &.is-error {
      margin: 16px 0 !important;
    }
  }
}
