@import "~@/erp/styles/variables/variables.scss";





















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.main-height {
  height: calc(100% - 56px);
}

.pro-container {
  height: 100%;
  overflow: hidden;
  overflow-y: auto;

  // 产品编码
  .prod_code,
  .sku-i {
    color: #409eff;
    text-decoration: underline;
    cursor: pointer;
  }

  .sku-i {
    text-decoration: none;
    margin-left: 5px;
  }

  // // 产品状态
  // .prod_state {
  //   text-decoration: underline;
  // }
  // 操作里面的按钮
  .btn-span {
    display: inline-block;
    width: 100%;
    height: 100%;
    line-height: 26px;
  }
}

//自定义显示
.custom-table-checkbox {
  .el-checkbox {
    display: block !important;
    margin: 8px 0 8px 10px !important;
  }
}

.save-btn {
  border-top: 1px solid #ececec;
  padding-top: 10px;
  text-align: center;
}

::v-deep {
  .page-header {
    .el-form-item {
      margin-bottom: 0;
    }
  }

  .el-dropdown-menu__item {
    width: 80px;
    text-align: center;
  }

  .el-dropdown .el-button-group {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .el-icon-arrow-down {
    font-size: 14px;
  }

  .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    background-color: #e8f4ff;
    color: #1890ff;
  }

  .el-radio-button__inner {
    padding: 0;
    width: 34px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  // 操作里面的按钮
  .el-dropdown-menu__item {
    line-height: 30px !important;

    a {
      width: 100%;
    }
  }

  .el-dropdown {
    .el-button-group {
      .el-button:first-child {
        width: 40px;
        height: 30px;
        padding: 2px;
      }
    }
  }

  .el-table--border th.gutter:last-of-type {
    display: table-cell !important;
  }
}
