@import "~@/erp/styles/variables/variables.scss";

















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































// 解决付款/发票tab下，表格抖动
.el-table {
  width: 99.9% !important;
}
.span-audit {
  text-decoration: underline;
  cursor: pointer;
}

.span-audit.pass {
  color: #008000;
}

.span-audit.process {
  color: #1890ff;
}

.span-audit.reject {
  color: #f56c6c;
}

.fileAnnex-span {
  display: inline-block;
  width: 15px;
  height: 15px;
  line-height: 15px;
  text-align: center;
  background: #9e9e9e;
  color: #fff;
  border-radius: 50%;
  margin-left: 5px;
  font-size: 12px;
}

.img-wrap {
  position: relative;
  padding: 3px 0;
  width: 50px;
  margin: 0 auto;

  .el-image {
    width: 50px;
    height: 50px;
    object-fit: cover;
    display: block;
  }

  .el-badge {
    position: absolute;
    top: 3px;
    right: -10px;
    z-index: 2;
  }
}

.no-return {
  font-size: 18px;
  font-family: PingFang Bold;
  color: #303133;
}

.upload-btn {
  display: inline-block;
  margin-left: 10px;
}

.file_list {
  .file_line {
    width: 360px;
    height: 32px;
    background: #f5f7fa;
    border-radius: 3px;
    border: 1px solid #ebeef5;
    line-height: 32px;
    font-size: 12px;
    color: #606266;
    position: relative;
    display: flex;
    justify-content: flex-start;
    margin-top: 5px;

    span {
      margin-right: 15px;
    }

    .icon_file {
      font-size: 16px;
      color: #fa6400;
      position: absolute;
      left: 5px;
      top: 8px;
    }

    .file_name {
      padding-left: 25px;
      width: 160px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .size {
      width: 70px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .icon_del {
      font-size: 16px;
      position: absolute;
      right: 5px;
      top: 8px;
      cursor: pointer;
    }
  }
}

.split-style {
  position: relative;
  padding-right: 15px;

  em {
    position: absolute;
    top: 2px;
    right: 0;
    z-index: 999;
    cursor: pointer;
  }
}

.el-icon-warning {
  position: absolute;
}

.relative {
  position: relative;
  display: inline-block;
}

.info-wrapper {
  text-align: left;
  width: 200px;

  p {
    width: 100%;
    padding: 2px 5px;
    white-space: break-spaces;
  }
}

::v-deep {
  .tool-tip {
    .el-icon-warning {
      position: unset !important;
    }
  }
  // 发货凭证
  .erp-upload-wrapper {
    display: flex;
    .upload_file {
      margin-top: -6px;
      .file_list {
        margin-top: 0;
        .file_line:first-of-type {
          margin-top: 0;
        }
      }
    }
  }
}

// 采购订单详情页，产品列表-印刷文件样式优化
// 此处仅处理当前页面，后面需要在custom.scss中统一处理，这里的样式可以删除
.flie-list {
  display: flex;

  .page-link {
    flex: 1;
    overflow: hidden;
    text-align: left;

    span.ell {
      max-width: none; // 去掉max-width: 300px;
      width: 100%;

      > a {
        width: 100%;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
