@import "~@/erp/styles/variables/variables.scss";













































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.el-form {
  padding: 0 10px;
  height: calc(100vh - 180px);
  overflow: auto;
  h4:first-child {
    margin: 0 0 10px;
  }
  .label-text {
    margin: 10px 0;
    text-align: left;
    color: #666;
  }
  .multi-icon {
    cursor: pointer;
    margin-left: 10px;
    color: #409eff;
    font-size: 16px;
  }
  .lot-sty {
    display: flex;
    justify-content: space-between;
    span {
      width: 180px;
      text-align: left;
      display: block;
    }
    i {
      font-size: 24px;
      cursor: pointer;
      color: #409eff;
    }
  }
  .lot-sty.no {
    justify-content: start;
  }
}
.oper-btn {
  position: sticky;
  bottom: 0;
  text-align: center;
  background: white;
  padding: 10px 0;
}
::v-deep {
  .el-select,
  .el-date-editor {
    width: 100%;
  }
  .el-table {
    .el-date-editor {
      width: 100%;
    }
  }
  .el-table__footer-wrapper {
    .has-gutter {
      height: 40px;
      font-family: 'PingFang Bold';
      .required-th::before {
        display: none;
      }
      td {
        text-align: center;
      }
    }
  }
}
