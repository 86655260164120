@import "~@/erp/styles/variables/variables.scss";
















































































































































































































































































































.content {
  padding: 0 10px 0;
  height: calc(100vh - 220px);
}

.oper-btn {
  position: sticky;
  bottom: 0;
  text-align: center;
  background: white;
  padding: 10px 0;
  z-index: 2;
}

::v-deep .required-icon {
  .el-form-item__label {
    &::before {
      content: '*';
      color: #ff4d4f;
      margin-right: 4px;
    }
  }
}

.el-select {
  width: 100%;
}
