@import "~@/erp/styles/variables/variables.scss";
































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.proSetting-container {
  display: flex;
  flex-direction: column;
  padding-bottom: 40px !important;
  .content {
    margin: 10px 0 20px 0;
    p {
      margin: 10px 0 20px 0;
    }
    .content-item {
      margin-bottom: 25px;
    }
    > ul {
      padding-left: 24px;
      li {
        list-style: none;
        line-height: 40px;
        .el-input {
          width: 200px;
        }
      }
    }
  }
  span {
    display: inline-block;
    margin: 0 5px;
    &.small-text {
      color: #aaaaaa;
      font-size: 12px;
    }
  }
  .button-wrapper {
    width: calc(100% - 152px);
    position: fixed;
    bottom: 10px;
    text-align: center;
    background-color: #fff;
  }
  .w100 {
    width: 100%;
  }
  .btn-wrapper {
    margin-top: 40px;
    text-align: center;
  }
}
