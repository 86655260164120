@import "~@/erp/styles/variables/variables.scss";












































































































































































































































































.english-translation-dialog {
  ::v-deep .el-table__body-wrapper {
    max-height: 60vh;
    overflow-y: auto;
    .el-table__expand-icon--expanded {
      display: none;
    }
  }
}
::v-deep .el-table__row--level-1 {
  background: #fff !important;
}
::v-deep .is-leaf {
  &:nth-child(3) {
    position: relative;
    .cell {
      text-indent: 8px;
    }
    &::before {
      content: '*';
      position: absolute;
      color: #f56c6c;
      top: 50%;
      left: 6px;
      transform: translateY(-50%);
      font-size: 14px;
    }
  }
}
.error {
  font-size: 12px;
  color: red;
}
