@import "~@/erp/styles/variables/variables.scss";


















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.main-height {
  height: calc(100% - 90px);
}

.table-height-box {
  height: calc(100% - 4px);
  overflow: auto;
  overflow-y: auto;
}

.custom-table-checkbox {
  .el-checkbox {
    display: block !important;
    margin: 8px 0 8px 10px !important;
  }
}

.save-btn {
  border-top: 1px solid #ececec;
  padding-top: 10px;
  text-align: center;
}

.width-200 {
  width: 200px;
}

.dot-span {
  display: inline-block;
  width: 15px;
  height: 15px;
  line-height: 15px;
  font-size: 12px;
  background: #409eff;
  color: #fff;
  border-radius: 50%;
  cursor: pointer;
  margin-left: 5px;
}

::v-deep {
  .cost {
    .el-popper {
      width: 0px !important;
    }

    .el-table__body-wrapper {
      height: 620px !important;
    }

    .el-table {
      overflow: auto;
      height: 660px !important;
    }
  }
}

.div-ellipis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.icon-zhaoshangyinhang {
  margin-right: 10px;
  font-size: 25px;
  cursor: pointer;
  position: relative;
  top: 4px;
}
.custom-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0 0;
}
