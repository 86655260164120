@import "~@/erp/styles/variables/variables.scss";





















































































































































































































































































































































































































































































































































































.oper-sticky {
  width: calc(100% - 105px);
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0;
  border-top: 1px solid #ccc;
  height: 80px;
  align-items: center;
  background-color: #fff;
}

.pro-button {
  border-color: rgb(40, 130, 255);
  color: rgb(40, 130, 255);
  margin: 15px 0;
}

.add-box {
  overflow: hidden;
  overflow-y: hidden;
  height: calc(100vh - 145px);
  margin-bottom: 100px !important;

  &.audit-box {
    height: auto !important;
  }
}

em {
  color: #000;
}

.el-form-item {
  margin-bottom: 0px !important;
}

.lot-wrapper {
  display: flex;
  align-items: center;

  .el-input {
    flex: 1;
  }

  .el-icon-circle-plus-outline {
    margin-left: 5px;
    cursor: pointer;
    font-size: 20px;
    color: rgb(40, 130, 255);
  }

}

.inline-block {
  display: inline-block;
}

.mr20 {
  margin-right: 20px;
}

::v-deep {
  .el-form-item__error {
    display: none;
  }
}

::v-deep {
  .col-remark {
    .vxe-cell {
      overflow: visible !important;
    }

  }
}
