@import "~@/erp/styles/variables/variables.scss";




























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.custom-container {
  padding-bottom: 0 !important;

  .disable-color {
    color: #c0c4cc;
  }

  .order-content {
    padding: 0 10px 0px;

    .el-divider {
      margin: 20px 0;
      background-color: #ececec;
    }

    h4 {
      font-size: 14px;
    }

    .text-sty {
      text-align: left;
      font-size: 13px;

      p {
        color: #606266;
        line-height: 20px;
      }
    }

    .el-input,
    .el-select {
      width: 100%;
    }

    .el-form-item {
      margin-bottom: 0;
    }

    .product-list {
      .product-table {
        /deep/ {
          .cell {
            overflow: initial;

            .el-form-item__content {
              padding: 5px 0;

              .el-form-item__error {
                top: 42px;
              }
            }
          }
          .special-form-item .el-form-item__error {
            top: 60px !important;
          }
        }
      }

      .total-money {
        line-height: 40px;
        text-align: right;

        span {
          font-family: 'PingFang Bold';
          font-size: 18px;
          margin-left: 5px;
        }
      }
    }

    .icon-fenpei {
      font-size: 24px;
      color: #409eff;
      vertical-align: middle;
      cursor: pointer;
    }

    .accessory {
      position: relative;
      padding-left: 10px;
    }

    .accessory::before {
      position: absolute;
      content: '*';
      color: red;
      font-size: 16px;
      top: 0;
      left: 0;
    }

    .tag-wrap {
      position: relative;
      display: flex;
      justify-content: center;

      .el-tag {
        position: absolute;
        top: -10px;
        right: 0;
        z-index: 2;
      }

      .el-image {
        width: 64px !important;
        height: 64px !important;
      }
    }
  }

  .oper-btn {
    position: sticky;
    bottom: 0;
    text-align: center;
    background: white;
    padding: 10px 0;
  }

  .bind-btn {
    width: 28px;
    height: 28px;
    text-align: center;

    i {
      font-size: 12px;
    }
  }

  .address-dialog {
    .container {
      .box {
        display: flex;
        justify-content: space-between;
        height: 50px;
        line-height: 50px;
        border-left: 1px solid #ececec;
        border-right: 1px solid #ececec;

        > div:first-child {
          width: 80%;
          padding: 0 20px;
          border-right: 1px solid #ececec;
          border-bottom: 1px solid #ececec;

          input {
            vertical-align: middle;
            margin-right: 5px;
            cursor: pointer;
          }
        }

        > div:last-child {
          width: 20%;
          text-align: center;
          border-bottom: 1px solid #ececec;
        }
      }

      .box:first-child {
        border-top: 1px solid #ececec;
      }
    }
  }

  /deep/.el-table table th.star div:before {
    content: '*';
    color: red;
  }

  .wrapper-box {
    .wrapper-add {
      display: inline-block;
      width: 50px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      border: 1px dotted #c0cbda;
      border-radius: 3px;
      cursor: pointer;
    }

    .wrapper-image img {
      height: 64px;
      width: 64px;
      margin: 0 auto;
    }
  }

  .deduction {
    display: flex;
    align-items: center;
    margin-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #ececec;

    .total-deduction {
      display: inline-block;
      margin-left: 20px;
      line-height: 30px;
    }
  }

  .address-btns {
    margin: 10px 0 20px;
    text-align: center;

    span {
      margin: 0 10px;
      color: #409eff;
      cursor: pointer;
    }
  }

  .discount-sty {
    position: relative;

    .el-button {
      position: absolute;
      top: 8px;
      right: -5px;
      z-index: 2;
      height: 28px;
      width: 28px;
      margin: 0;

      /deep/ i {
        font-size: 16px;
        margin: -3px;
      }
    }

    /deep/ {
      .el-input {
        width: 100px;
        display: flex;
      }
    }
  }
}

.custom-container {
  padding-bottom: 0 !important;

  .order-content {
    padding: 0 10px 0px;

    .el-divider {
      margin: 20px 0;
      background-color: #ececec;
    }

    h4 {
      font-size: 14px;
    }

    .text-sty {
      text-align: left;

      p {
        color: #606266;
        line-height: 20px;
      }
    }

    .el-input,
    .el-select {
      width: 100%;
    }

    .el-form-item {
      margin-bottom: 0;
    }

    .product-list {
      .product-table {
        /deep/ {
          .cell {
            overflow: initial;

            .el-form-item__content {
              padding: 5px 0;

              .el-form-item__error {
                top: 42px;
              }
            }
          }
        }
      }

      .total-money {
        line-height: 40px;
        text-align: right;

        span {
          font-family: 'PingFang Bold';
          font-size: 18px;
          margin-left: 5px;
        }
      }
    }

    .icon-fenpei {
      font-size: 24px;
      color: #409eff;
      vertical-align: middle;
      cursor: pointer;
    }

    .accessory {
      position: relative;
      padding-left: 10px;
    }

    .accessory::before {
      position: absolute;
      content: '*';
      color: red;
      font-size: 16px;
      top: 0;
      left: 0;
    }

    .tag-wrap {
      position: relative;
      display: flex;
      justify-content: center;

      .el-tag {
        position: absolute;
        top: -10px;
        right: 0;
        z-index: 2;

        &.el-tag-other {
          background-color: #fff7e8;
          border-color: #ffefd1;
          color: #ffae18;
        }
      }

      .el-image {
        width: 64px !important;
        height: 64px !important;
      }
    }
  }

  .company-name {
    position: relative;

    .list {
      position: absolute;
      top: 60px;
      left: 0;
      width: 100%;
      z-index: 999;
      box-shadow: 0 2px 12px 0 #ccc;
      border: 1px solid #e4e7ed;
      background: white;
      padding: 5px 0;

      p {
        font-size: 12px;
        color: #999;
        text-align: left;
        display: flex;
        justify-content: space-between;

        i {
          font-size: 18px;
          color: #333;
          cursor: pointer;
        }
      }

      ul {
        min-height: 200px;
        max-height: 200px;
        overflow-y: auto;

        li {
          padding: 0 10px 5px;
          height: 34px;
          line-height: 34px;
          display: flex;
          justify-content: space-between;
          cursor: pointer;

          span {
            width: 45%;
          }
        }

        li:hover {
          background: #f5f7fa;
        }
      }
    }
  }

  .oper-btn {
    position: sticky;
    bottom: 0;
    text-align: center;
    background: white;
    padding: 10px 0;
  }

  .bind-btn {
    width: 28px;
    height: 28px;
    text-align: center;

    i {
      font-size: 12px;
    }
  }

  .address-dialog {
    .container {
      .box {
        display: flex;
        justify-content: space-between;
        height: 50px;
        line-height: 50px;
        border-left: 1px solid #ececec;
        border-right: 1px solid #ececec;

        > div:first-child {
          width: 80%;
          padding: 0 20px;
          border-right: 1px solid #ececec;
          border-bottom: 1px solid #ececec;

          input {
            vertical-align: middle;
            margin-right: 5px;
            cursor: pointer;
          }
        }

        > div:last-child {
          width: 20%;
          text-align: center;
          border-bottom: 1px solid #ececec;
        }
      }

      .box:first-child {
        border-top: 1px solid #ececec;
      }
    }
  }

  /deep/.el-table table th.star div:before {
    content: '*';
    color: red;
  }

  .wrapper-box {
    .wrapper-add {
      display: inline-block;
      width: 50px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      border: 1px dotted #c0cbda;
      border-radius: 3px;
      cursor: pointer;
    }

    .wrapper-image img {
      height: 64px;
      width: 64px;
      margin: 0 auto;
    }
  }

  .deduction {
    display: flex;
    align-items: center;
    margin-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #ececec;

    .total-deduction {
      display: inline-block;
      margin-left: 20px;
      line-height: 30px;
    }
  }

  .address-btns {
    margin: 10px 0 20px;
    text-align: center;

    span {
      margin: 0 10px;
      color: #409eff;
      cursor: pointer;
    }
  }

  .discount-sty {
    position: relative;

    .el-button {
      position: absolute;
      top: 8px;
      right: -5px;
      z-index: 2;
      height: 28px;
      width: 28px;
      margin: 0;

      /deep/ i {
        font-size: 16px;
        margin: -3px;
      }
    }

    /deep/ {
      .el-input {
        width: 100px;
        display: flex;
      }
    }
  }

  .cost-calculation {
    width: 24px;
    height: 24px;
    padding: 0;
    flex-shrink: 0;
    margin-left: 4px;

    .icon-feiyongjisuan {
      font-size: 16px;
    }
  }
}

.tip {
  color: #ff4d4f;
  font-family: 'PingFang Bold';
  padding-left: 70px;

  & + .el-divider {
    margin-top: 0 !important;
  }
}

.flex {
  display: flex;
  align-items: center;
  .el-tooltip {
    margin-left: 10px;
  }
}
.client-remark {
  .client-remark-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #515a6e;
    span {
      width: 70px;
      flex-shrink: 0;
    }
    p {
      width: 180px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
    }
    .el-button--text {
      text-decoration: underline;
    }
  }
}
.remark-container {
  .el-divider {
    margin: 0 !important;
  }
}
// 批量设置
.batch-set {
  margin-left: auto !important;
  color: #1890ff;
  border-color: #badeff;
  > span {
    > img {
      float: left;
    }
    > span {
      margin-left: 5px;
      float: right;
      line-height: 16px;
    }
  }
}
