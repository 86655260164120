@import "~@/erp/styles/variables/variables.scss";










































































































































































































































































































































































































































































































.detail-container {
  .content {
    margin-bottom: 100px;
  }
}

.operate {
  height: 60px;
  line-height: 60px;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 0 40px;
  background: #fff;
  border-top: 1px solid #ededed;
  z-index: 1000;
}
