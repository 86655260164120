@import "~@/erp/styles/variables/variables.scss";





















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.custom-container {
  .account-num {
    span {
      font-size: 16px;
      margin-right: 5px;
      font-family: 'PingFang Bold';
    }
  }
}

.img-wrap {
  position: relative;
  padding: 3px 0;
  width: 50px;
  margin: 0 auto;

  .el-image {
    width: 50px;
    height: 50px;
    object-fit: cover;
    display: block;
  }

  .el-badge {
    position: absolute;
    top: 3px;
    right: -10px;
    z-index: 2;
  }
}

.supplierName-row {
  .tooltipOver {
    display: inline-block;
    width: 10px;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.file_list {
  .file_line {
    width: 420px;
    height: 32px;
    background: #f5f7fa;
    border-radius: 3px;
    border: 1px solid #ebeef5;
    line-height: 32px;
    font-size: 12px;
    color: #606266;
    position: relative;
    display: flex;
    justify-content: flex-start;
    margin-top: 5px;

    span {
      margin-right: 15px;
    }

    .icon_file {
      font-size: 16px;
      color: #fa6400;
      position: absolute;
      left: 5px;
      top: 8px;
    }

    .file_name {
      padding-left: 25px;
      width: 160px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .size {
      width: 70px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.wrap {
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  vertical-align: bottom;
  text-align: left;
}
// 采购订单详情页，产品列表-印刷文件样式优化
// 此处仅处理当前页面，后面需要在custom.scss中统一处理，这里的样式可以删除
.flie-list {
  display: flex;
  .page-link {
    flex: 1;
    overflow: hidden;
    text-align: left;
    span.ell {
      max-width: none; // 去掉max-width: 300px;
      width: 100%;
      > a {
        width: 100%;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
