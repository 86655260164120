@import "~@/erp/styles/variables/variables.scss";



















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.table-container {
  overflow-y: hidden !important;
}

.page-header {
  height: 40px;
}

.w-95 {
  width: 95%;
}

.ml-15 {
  margin-left: 15px;
}

::v-deep {
  .page-header {
    .el-form-item {
      margin-bottom: 0;
    }
  }
}

.radio-wrap {
  display: flex;
  justify-content: space-between;
}

.divider {
  height: 1px;
  background-color: #f5f7fa;
  margin: 9px 0 15px 0;
}

.pointer {
  cursor: pointer;
}

i {
  font-size: 18px;
  margin: 0 6px;
}

.link {
  text-decoration: underline;
  color: #1890ff;
  display: inline-block;
}

.progress {
  padding: 15px 20px;

  .progress-wrapper {
    display: flex;

    // flex-direction: column;
    & .left {
      display: flex;
      flex-direction: column;
      align-items: center;

      & .line {
        flex: 1;
        width: 1px;
        background-color: #c0c4cc;
        margin-top: 5px;
      }
    }

    .right {
      display: flex;
      flex-direction: column;

      & span {
        display: inline-block;
        margin-right: 15px;
      }
    }
  }
}

.save-btn {
  border-top: 1px solid #ececec;
  padding-top: 10px;
  text-align: center;
}

.mt15 {
  margin-top: 15px;
}

.error-color {
  color: #f56c6c;
}

.success-color {
  color: #67c23a;
}

.warning-color {
  color: #e6a23c;
}

.war .pointer {
  cursor: pointer;
}

.span {
  cursor: pointer;
  max-width: 90%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.split-div {
  height: 1px;
  background-color: #dcdfe6;
  margin: 5px 0;
}
.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}
